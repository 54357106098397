export enum RecipeComponentType {
  Input = 'Input',
  Output = 'Output'
}

export enum ProductForecastStatus {
  Requested = 'Requested',
  Success = 'Success'
}

export enum Feature {
  Stock = 'stock_screen',
  PriceForecast = 'price_forecast_screen',
  DemandForecast = 'demand_forecast_screen',
  Planning = 'planning_screen',
  ElectricityPurchasing = 'electricity_purchasing',
  ControlOperatorScreen = 'control_room_operator_view',
  PlanningComments = 'planning_comments',
  OperatorViewExactElectricityPrices = 'operator_view_exact_electricity_prices',
  OperatorViewColorCodedElectricityPrices = 'operator_view_color_coded_electricity_prices',
  ShutdownAvailability = 'shutdown_availability',
  CostAvoidance = 'cost_avoidance',
  // TODO: to be removed once standardOperationTimes are new fully implemented
  NewStandardOperationTimes = 'newStandardOperationTimes',
  NewMaterialType = 'new_material_type'
}
