import {MaterialType, NewMaterialType} from '@hconnect/common/types'

import {useSettingsQuery} from '../../../common/providers/ApiInterface'
import {useUrlParam} from '../../../routing'

export const useMaterialsQuery = (materialType?: MaterialType | NewMaterialType) => {
  const plantCode = useUrlParam('plantCode')
  return useSettingsQuery('materials', [plantCode], {
    select: (materials) =>
      materials
        .filter((material) => (materialType ? material.type === materialType : true))
        .sort((a, b) => a.name.localeCompare(b.name))
  })
}
