import {Loader} from '@hconnect/uikit/src/lib2'
import {ErrorOutline} from '@material-ui/icons'
import {Done} from '@mui/icons-material'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tabs,
  Tab,
  Box,
  Paper,
  DialogProps,
  Chip,
  Tooltip,
  Alert
} from '@mui/material'
import {styled} from '@mui/material/styles'
import {Stack} from '@mui/system'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useSettingsQuery} from '../../../common/providers'
import {StructureNodeUnist} from '../../../common/types'
import {useUrlParam} from '../../../routing'

import {MaterialStorages} from './MaterialStorages'
import {ProductionAssets} from './ProductionAssets'

const StyledDialog = styled(Dialog)<DialogProps>(() => ({
  '& .MuiDialog-paper': {
    width: '80%',
    maxWidth: 'none'
  }
}))

interface AddSubdomainPopupProps {
  upmId: string | null
  nodesById?: Record<string, StructureNodeUnist>
  name: string
  close: () => void
  closeAndSubmit: (values: {assetId?: number; storageId?: number; upmId: string}) => void
}

const AddSubdomainPopup: React.FC<AddSubdomainPopupProps> = ({
  upmId,
  name,
  close,
  nodesById,
  closeAndSubmit
}) => {
  const plantCode = useUrlParam('plantCode')
  const {
    data: assets,
    isLoading: isLoadingAssets,
    isFetching: isFetchingAssets
  } = useSettingsQuery('assets', [plantCode])
  const {
    data: materialStorages,
    isLoading: isLoadingStorages,
    isFetching: isFetchingStorages
  } = useSettingsQuery('materialStorage', [plantCode])

  const [activeTab, setActiveTab] = useState(0)
  const [selectedAssetId, setSelectedAssetId] = useState<number | undefined>()
  const [selectedStorage, setSelectedStorageId] = useState<number | undefined>()
  const [alertVisible, setAlertVisible] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const {t} = useTranslation()

  const isLoadingOrFetching =
    isLoadingAssets || isLoadingStorages || isFetchingAssets || isFetchingStorages

  useEffect(() => {
    const attachedAssets = assets?.filter((asset) => selectedAssetId === asset.id && asset.upmId)
    const attachedStorages = materialStorages?.filter(
      (storage) => selectedStorage === storage.id && storage.upmId
    )

    if (attachedAssets?.length || attachedStorages?.length) {
      setAlertMessage(t('janusConfig.plantSetup.assetsOrStoragesAlreadyAttached'))
      setAlertVisible(true)
    } else {
      setAlertVisible(false)
    }
  }, [selectedAssetId, selectedStorage, assets, materialStorages, t])

  const handleTabChange = (_: React.ChangeEvent<object>, newValue: number) => {
    setActiveTab(newValue)
  }
  const handleClose = () => {
    setSelectedStorageId(undefined)
    setSelectedAssetId(undefined)
    setActiveTab(0)
    close()
  }
  const handleCloseAndSubmit = () => {
    if (upmId && (!!selectedAssetId || selectedStorage)) {
      closeAndSubmit({assetId: selectedAssetId, storageId: selectedStorage, upmId: upmId})
    }
    handleClose()
  }
  const selectedAssetsChips = assets && !!selectedAssetId && (
    <Box>
      <h3>{t('janusConfig.plantSetup.selectedAsset')}</h3>
      <Box display="flex" flexWrap="wrap" gap={1}>
        {assets
          .filter((asset) => selectedAssetId === asset.id)
          .map((asset) =>
            !asset.upmId ? (
              <Chip
                key={asset.id}
                variant="outlined"
                color="success"
                label={asset.name}
                icon={<Done />}
              />
            ) : (
              <Tooltip
                key={asset.id}
                title={t('alreadyAttachedTo', {name: nodesById?.[asset.upmId]?.data.name})}
              >
                <Chip
                  variant="outlined"
                  color="warning"
                  label={asset.name}
                  icon={<ErrorOutline />}
                />
              </Tooltip>
            )
          )}
      </Box>
    </Box>
  )
  const selectedStoragesChips = !!selectedStorage && (
    <Box>
      <h3>{t('janusConfig.plantSetup.selectedMaterialStorage')}</h3>
      <Box display="flex" flexWrap="wrap" gap={1}>
        {materialStorages &&
          materialStorages
            .filter((storage) => selectedStorage === storage.id)
            .map((storage) =>
              !storage.upmId ? (
                <Chip
                  key={storage.id}
                  variant="outlined"
                  color="success"
                  label={storage.name}
                  icon={<Done />}
                />
              ) : (
                <Tooltip
                  key={storage.id}
                  title={t('alreadyAttachedTo', {name: nodesById?.[storage.upmId]?.data.name})}
                >
                  <Chip
                    variant="outlined"
                    color="warning"
                    label={storage.name}
                    icon={<ErrorOutline />}
                  />
                </Tooltip>
              )
            )}
      </Box>
    </Box>
  )

  if (isLoadingOrFetching) {
    return <Loader />
  }

  return (
    <StyledDialog open={!!upmId} onClose={handleClose}>
      <DialogTitle>{t('janusConfig.plantSetup.addSubdomainTo', {name})}</DialogTitle>
      <DialogContent>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label={t('janusConfig.plantSetup.productionAssets', {count: assets?.length ?? 0})} />
          <Tab
            label={t('janusConfig.plantSetup.materialStorages', {
              count: materialStorages?.length ?? 0
            })}
          />
        </Tabs>
        <Stack spacing={2}>
          <Paper>
            {activeTab === 0 && (
              <ProductionAssets
                assets={assets}
                nodesById={nodesById}
                selectedAssetId={selectedAssetId}
                setSelectedAssetId={setSelectedAssetId}
              />
            )}
            {activeTab === 1 && (
              <MaterialStorages
                materialStorages={materialStorages}
                nodesById={nodesById}
                selectedStorageId={selectedStorage}
                setSelectedStorageId={setSelectedStorageId}
              />
            )}
          </Paper>
          <Box display="flex" flexDirection="row" gap={4}>
            {selectedAssetsChips}
            {selectedStoragesChips}
          </Box>
          {alertVisible && <Alert severity="warning">{alertMessage}</Alert>}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color="secondary">
          {t('janusConfig.plantSetup.close')}
        </Button>
        <Button
          onClick={() => handleCloseAndSubmit()}
          color="primary"
          disabled={!selectedAssetId && !selectedStorage}
        >
          {t('janusConfig.plantSetup.associateAllWith', {name})}
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

export {AddSubdomainPopup}
