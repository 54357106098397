import {AssetPartLocation} from './KilnModel.types'
import {Bearing, BoxPart, GirthGear, LocationPill, Pinion} from './KilnParts'

import {DriveLayout} from '@hconnect/common/components/kmsStatus'

export enum PinionParts {
  BEARING_LEFT = 'bearing-left',
  BEARING_RIGHT = 'bearing-right',
  PINION = 'pinion'
}

export enum OpenDriveParts {
  GIRTH_GEAR = 'girth-gear',
  DRIVE_TOP = 'top',
  DRIVE_BOTTOM = 'bottom'
}

export enum DriveParts {
  MOTOR = 'motor',
  GEARBOX = 'gearbox',
  PINION = 'pinion'
}

export const buildDriveLocations = ({id, position}: Pick<DriveLayout, 'id' | 'position'>) => {
  const girthGearNumber = position === 'bottom' ? 1 : 6
  const bottomDriveStart = position === 'double' ? 7 : 2
  const bottomDriveOffsetY = position === 'bottom' ? -60 : 0

  const locations: AssetPartLocation[] = [
    {
      id: `${id}_${OpenDriveParts.GIRTH_GEAR}`,
      label: 'Girth gear',
      locationNumber: girthGearNumber,
      x: 90,
      y: 140 + bottomDriveOffsetY
    }
  ]

  if (position === 'top' || position === 'double') {
    locations.push(
      {
        id: `${id}_${OpenDriveParts.DRIVE_TOP}_${DriveParts.MOTOR}`,
        label: 'Motor',
        locationNumber: 1,
        x: 10,
        y: 3
      },
      {
        id: `${id}_${OpenDriveParts.DRIVE_TOP}_${DriveParts.GEARBOX}`,
        label: 'Gearbox',
        locationNumber: 2,
        x: 10,
        y: 48
      },
      {
        id: `${id}_${OpenDriveParts.DRIVE_TOP}_${DriveParts.PINION}_${PinionParts.BEARING_LEFT}`,
        label: 'Bearing',
        locationNumber: 3,
        x: 50,
        y: 24
      },
      {
        id: `${id}_${OpenDriveParts.DRIVE_TOP}_${DriveParts.PINION}_${PinionParts.PINION}`,
        label: 'Pinion',
        locationNumber: 4,
        x: 90,
        y: 35
      },
      {
        id: `${id}_${OpenDriveParts.DRIVE_TOP}_${DriveParts.PINION}_${PinionParts.BEARING_RIGHT}`,
        label: 'Bearing',
        locationNumber: 5,
        x: 150,
        y: 48
      }
    )
  }

  if (position === 'bottom' || position === 'double') {
    locations.push(
      {
        id: `${id}_${OpenDriveParts.DRIVE_BOTTOM}_${DriveParts.MOTOR}`,
        label: 'Motor',
        locationNumber: bottomDriveStart + 4,
        x: 10,
        y: 300 + bottomDriveOffsetY
      },
      {
        id: `${id}_${OpenDriveParts.DRIVE_BOTTOM}_${DriveParts.GEARBOX}`,
        label: 'Gearbox',
        locationNumber: bottomDriveStart + 3,
        x: 10,
        y: 255 + bottomDriveOffsetY
      },
      {
        id: `${id}_${OpenDriveParts.DRIVE_BOTTOM}_${DriveParts.PINION}_${PinionParts.BEARING_LEFT}`,
        label: 'Bearing',
        locationNumber: bottomDriveStart + 2,
        x: 50,
        y: 280 + bottomDriveOffsetY
      },
      {
        id: `${id}_${OpenDriveParts.DRIVE_BOTTOM}_${DriveParts.PINION}_${PinionParts.PINION}`,
        label: 'Pinion',
        locationNumber: bottomDriveStart + 1,
        x: 90,
        y: 265 + bottomDriveOffsetY
      },
      {
        id: `${id}_${OpenDriveParts.DRIVE_BOTTOM}_${DriveParts.PINION}_${PinionParts.BEARING_RIGHT}`,
        label: 'Bearing',
        locationNumber: bottomDriveStart,
        x: 150,
        y: 255 + bottomDriveOffsetY
      }
    )
  }

  locations.sort((a, b) => a.locationNumber - b.locationNumber)
  return locations
}

export const DrivePart = ({
  id,
  x,
  y,
  width,
  height,
  position,
  showLocation = false,
  flip = false
}: DriveLayout) => {
  const yOffset = position === 'bottom' ? 0 : 60

  return (
    <svg x={x} y={y} width={width} height={height}>
      <g transform={flip ? `scale(-1, 1) translate(${-width}, 0)` : ''}>
        {/* TOP OR DUAL DRIVE */}
        {position !== 'bottom' && (
          <svg>
            <line x1={48} y1={59} x2={60} y2={59} stroke="#00274D" strokeWidth={2} />
            <line x1={23} y1={32} x2={23} y2={44} stroke="#00274D" strokeWidth={2} />
            <Bearing
              id={`${id}_${OpenDriveParts.DRIVE_TOP}_${DriveParts.PINION}_${PinionParts.BEARING_LEFT}`}
              y={52}
              x={60}
            />
            <Bearing
              id={`${id}_${OpenDriveParts.DRIVE_TOP}_${DriveParts.PINION}_${PinionParts.BEARING_RIGHT}`}
              y={52}
              x={112}
            />
            <Pinion
              id={`${id}_${OpenDriveParts.DRIVE_TOP}_${DriveParts.PINION}_${PinionParts.PINION}`}
              x={72}
              y={44}
            />
            <BoxPart id={`${id}_${OpenDriveParts.DRIVE_TOP}_${DriveParts.GEARBOX}`} x={0} y={44} />
            <BoxPart id={`${id}_${OpenDriveParts.DRIVE_TOP}_${DriveParts.MOTOR}`} x={0} y={0} />
          </svg>
        )}
        {/* BOTTOM OR DUAL DRIVE */}
        {position !== 'top' && (
          <svg y={yOffset}>
            <line x1={48} y1={207} x2={60} y2={207} stroke="#00274D" strokeWidth={2} />
            <line x1={23} y1={224} x2={23} y2={236} stroke="#00274D" strokeWidth={2} />
            <Bearing
              id={`${id}_${OpenDriveParts.DRIVE_BOTTOM}_${DriveParts.PINION}_${PinionParts.BEARING_LEFT}`}
              y={200}
              x={60}
            />
            <Bearing
              id={`${id}_${OpenDriveParts.DRIVE_BOTTOM}_${DriveParts.PINION}_${PinionParts.BEARING_RIGHT}`}
              y={200}
              x={112}
            />
            <Pinion
              id={`${id}_${OpenDriveParts.DRIVE_BOTTOM}_${DriveParts.PINION}_${PinionParts.PINION}`}
              x={72}
              y={192}
            />
            <BoxPart
              id={`${id}_${OpenDriveParts.DRIVE_BOTTOM}_${DriveParts.GEARBOX}`}
              x={0}
              y={192}
            />
            <BoxPart
              id={`${id}_${OpenDriveParts.DRIVE_BOTTOM}_${DriveParts.MOTOR}`}
              x={0}
              y={236}
            />
          </svg>
        )}
        <GirthGear id={`${id}_${OpenDriveParts.GIRTH_GEAR}`} x={75} y={yOffset} />
        {showLocation && renderLocationPills({id, pillOffsetY: 0, position, flip: flip})}
      </g>
    </svg>
  )
}

const renderLocationPills = ({id, pillOffsetY, position, ...props}) => {
  const locationPills = buildDriveLocations({id, position})

  return locationPills.map((pill) => (
    <LocationPill
      key={pill.locationNumber}
      x={pill.x}
      y={pill.y + pillOffsetY}
      text={pill.locationNumber}
      {...props}
    />
  ))
}
