import {dataTestId} from '@hconnect/uikit'
import {UploadFile as UploadFileIcon} from '@mui/icons-material'
import {Box, Button, Stack, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ProgressBar} from '../common/ProgressBar'

export const CategoriesHeader = ({
  selectedDate,
  progress
}: {
  selectedDate: string
  progress: number
}) => {
  const {t} = useTranslation()

  return (
    <Stack gap={1} {...dataTestId('categories-tree-header')}>
      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        justifyContent="space-between"
        pb={2}
        alignItems="center"
      >
        <Typography variant="h4">{selectedDate}</Typography>
        <Button variant="text" startIcon={<UploadFileIcon />}>
          {t('janusConfig.monthlyEntry.reUpload')}
        </Button>
      </Box>
      <ProgressBar
        primaryText={t('janusConfig.dailyEntry.progress')}
        secondaryText={`${progress}%`}
        progress={progress}
        height={12}
        testId="monthly-progress-bar"
        width="100%"
        backgroundColor="rgba(232, 236, 240, 1)"
        textColor="rgba(82, 102, 122, 1)"
      />
    </Stack>
  )
}
