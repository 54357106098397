import {dataTestId} from '@hconnect/uikit'
import {Box, TextField, ToggleButton, ToggleButtonGroup} from '@mui/material'
import {isNil} from 'lodash'
import React, {FC, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {ControlledAutocomplete, Switch} from '../../../common/components'
import {submitOnBlurAndEnterProps} from '../../../common/utils'
import {useUrlParam} from '../../../routing'
import {useEditKpiDisplayName} from '../../hooks/useEditKpiDisplayName'

import {
  useEditKpiFrequency,
  useEditKpiUnit,
  useUnits,
  useEditKpiPlantEntryPointVisibility
} from '@settings/modules/kpi-calculation/hooks'
import {Frequency} from '@settings/modules/kpi-calculation/types'

type KpiUnitOption = {id: string; label: string}

type KpiFormProps = {
  kpiTagname: string
  kpiDisplayName: string
  kpiUnit: string | null
  kpiId: number
  frequency: Frequency
  plantEntryPointVisibility: boolean
}
type DropDownConfig = {
  value: Frequency
  label: string
}
const TIME_STEP_BUTTON_CONFIG: DropDownConfig[] = [
  {
    value: Frequency.Daily,
    label: 'kpiCalculationSettings.label.daily'
  },
  {
    value: Frequency.Weekly,
    label: 'kpiCalculationSettings.label.weekly'
  },
  {
    value: Frequency.Monthly,
    label: 'kpiCalculationSettings.label.monthly'
  },
  {
    value: Frequency.Yearly,
    label: 'kpiCalculationSettings.label.yearly'
  }
]
const FIELD_WIDTH = {xs: '100%', md: '60%'}

const mapUnitsToOptions = (units: string[]): KpiUnitOption[] =>
  units.map((unit) => ({
    id: unit,
    label: unit
  }))

export const KpiForm: FC<KpiFormProps> = ({
  kpiTagname,
  kpiDisplayName,
  kpiId,
  kpiUnit,
  frequency,
  plantEntryPointVisibility
}) => {
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')

  const [timeStep, setTimeStep] = useState<Frequency>(frequency)

  const [units, setUnits] = useState<KpiUnitOption[]>([])
  const unitsResponse = useUnits(kpiId, (list: string[]) => {
    setUnits(mapUnitsToOptions(list))
  })
  const unitsList: string[] = unitsResponse.data || []
  const {mutate: editKpiDisplayName} = useEditKpiDisplayName()
  const {mutate: editKpiUnit} = useEditKpiUnit()
  const {mutate: editKpiFrequency} = useEditKpiFrequency()
  const {mutate: editKpiPlantEntryPointVisibility} = useEditKpiPlantEntryPointVisibility()
  const {
    handleSubmit,
    control,
    reset,
    formState: {dirtyFields}
  } = useForm<{
    kpiDefinitionId: number
    kpiName: string
    displayName: string
    unit: KpiUnitOption
    plantEntryPointVisibility: boolean
  }>({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      kpiDefinitionId: kpiId,
      kpiName: kpiTagname,
      displayName: kpiDisplayName,
      unit: kpiUnit ? {id: kpiUnit, label: kpiUnit} : undefined,
      plantEntryPointVisibility
    }
  })

  const handleAddCustomUnit = (customUnit: string) => {
    if (!customUnit) {
      setUnits(mapUnitsToOptions(unitsList))
      return
    }
    const newUnit = {id: customUnit.toLowerCase(), label: customUnit}
    setUnits([...mapUnitsToOptions(unitsList), newUnit])
  }

  const submit = handleSubmit(({displayName, unit}) => {
    if (dirtyFields.displayName) {
      editKpiDisplayName(
        {displayName, kpiId, plantCode},
        {
          onError: () => reset(),
          onSuccess: () => {
            reset({displayName}, {keepValues: true})
          }
        }
      )
    }
    if (dirtyFields.unit) {
      editKpiUnit(
        {unit: unit.id, kpiId, plantCode},
        {
          onError: () => reset(),
          onSuccess: () => {
            reset({unit}, {keepValues: true})
          }
        }
      )
    }
  })

  const handleTimeStepChange = (frequency: Frequency) => {
    if (isNil(frequency)) return
    setTimeStep(frequency)
    editKpiFrequency({frequency, kpiId, plantCode})
  }

  const submitPlantEntryPointVisibility = (plantEntryPointVisibility: boolean) => {
    editKpiPlantEntryPointVisibility(
      {plantEntryPointVisibility, kpiId, plantCode},
      {
        onError: () => reset(),
        onSuccess: () => {
          reset({plantEntryPointVisibility}, {keepValues: true})
        }
      }
    )
  }

  return (
    <Box
      {...dataTestId('edit_kpi_form')}
      gap={1.5}
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Controller
        control={control}
        name="kpiDefinitionId"
        render={({field: {ref, value, onChange}, fieldState: {error}}) => (
          <TextField
            label={t('kpiCalculationSettings.label.kpiDefinitionId')}
            sx={{width: FIELD_WIDTH}}
            value={value}
            placeholder={String(value)}
            inputRef={ref}
            variant="filled"
            onChange={onChange}
            helperText={error?.message}
            error={Boolean(error?.message)}
            disabled
            {...dataTestId('kpi_definition_id_input')}
          />
        )}
      />
      <Controller
        control={control}
        name="kpiName"
        render={({field: {ref, value, onChange}, fieldState: {error}}) => (
          <TextField
            label={t('kpiCalculationSettings.label.kpiName')}
            sx={{width: FIELD_WIDTH}}
            value={value}
            placeholder={value}
            inputRef={ref}
            variant="filled"
            onChange={onChange}
            helperText={error?.message}
            error={Boolean(error?.message)}
            disabled
            {...dataTestId('kpi_name_input')}
          />
        )}
      />
      <Controller
        control={control}
        name="displayName"
        render={({field: {ref, value, onChange}, fieldState: {error}}) => (
          <TextField
            label={t('kpiCalculationSettings.label.kpiDisplayName')}
            sx={{width: FIELD_WIDTH}}
            value={value}
            placeholder={value}
            inputRef={ref}
            variant="filled"
            onChange={onChange}
            helperText={error?.message}
            error={Boolean(error?.message)}
            {...submitOnBlurAndEnterProps(submit)}
            {...dataTestId('kpi_name_display_input')}
          />
        )}
      />
      <Box sx={{width: FIELD_WIDTH}}>
        <ControlledAutocomplete
          formDataName="unit"
          label={t('kpiCalculationSettings.label.unit')}
          control={control}
          options={units}
          onSubmit={submit}
          onAddCustomValue={handleAddCustomUnit}
          {...dataTestId('kpi_name_units_input')}
        />
      </Box>
      <ToggleButtonGroup
        value={timeStep}
        onChange={(_e, value: Frequency) => handleTimeStepChange(value)}
        fullWidth
        sx={{
          width: FIELD_WIDTH
        }}
        color="primary"
        exclusive
      >
        {TIME_STEP_BUTTON_CONFIG.map(({value, label}) => (
          <ToggleButton key={value} value={value} {...dataTestId(`time-step-${value}`)}>
            {t(label)}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <Controller
        control={control}
        name="plantEntryPointVisibility"
        render={({field: {ref, value, onChange}}) => (
          <Switch
            label={t('kpiCalculationSettings.label.plantEntryPointVisibility')}
            inputRef={ref}
            value={value}
            onChange={(checked) => {
              onChange(checked)
              submitPlantEntryPointVisibility(checked)
            }}
            {...dataTestId('plant_entry_point_visibility_switch')}
          />
        )}
      />
    </Box>
  )
}
