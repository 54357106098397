import {MaterialSource, Status} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {Add} from '@mui/icons-material'
import {Button} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {usePermission} from '../../../permissions'
import {AutocompleteOption, Material} from '../../types'
import {useSelectedMaterial} from '../SelectedMaterialProvider'

import {useMaterialsTreeData} from './hooks/useMaterialsTreeData'
import {useMaterialsWithGlobalName} from './hooks/useMaterialsWithGlobalName'
import {MaterialsTree} from './MaterialsTree'
import {MaterialsTreeFilters, FilterOptions} from './MaterialsTreeFilters'

interface MaterialsStructureProps {
  materials: Material[]
  globalMaterialOptions: AutocompleteOption[]
}

export const MaterialsStructure: React.FC<MaterialsStructureProps> = ({
  materials,
  globalMaterialOptions
}) => {
  const {t} = useTranslation()
  const canChangeMaterials = usePermission('CHANGE_MATERIALS')

  const [treeQuickFilter, setTreeQuickFilter] = useState<FilterOptions>(FilterOptions.All)
  const [treeFilterText, setTreeFilterText] = useState<string>()

  const {selectedMaterial, setSelectedMaterial} = useSelectedMaterial()

  const materialsWithGlobalName = useMaterialsWithGlobalName(materials, globalMaterialOptions)
  const materialsTreeData = useMaterialsTreeData(materialsWithGlobalName, selectedMaterial)

  return (
    <CardBox height="100vh">
      <MaterialsTreeFilters
        treeQuickFilter={treeQuickFilter}
        setTreeQuickFilter={setTreeQuickFilter}
        setTreeFilterText={setTreeFilterText}
      />
      <Button
        variant="outlined"
        color="secondary"
        startIcon={<Add />}
        disabled={
          !canChangeMaterials || (selectedMaterial && selectedMaterial.status === Status.New)
        }
        onClick={() => {
          setSelectedMaterial({
            name: '',
            source: MaterialSource.ProducedInPlant,
            status: Status.New,
            pxTrendCounters: []
          })
        }}
        sx={{mt: '24px', mb: '24px'}}
        {...dataTestId('settings-tree-add-new-material-button')}
      >
        {t('materialsSettings.addMaterial')}
      </Button>
      <MaterialsTree
        treeData={materialsTreeData}
        treeQuickFilter={treeQuickFilter}
        treeFilterText={treeFilterText}
      />
    </CardBox>
  )
}
