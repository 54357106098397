import {dataTestId} from '@hconnect/uikit'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {LayoutSettings, PageLayout, SettingsHelmet, SettingsSkeleton} from '../../common/components'
import {useLatestQuery} from '../../common/hooks/queries/useLatestQuery'
import {RouteName} from '../../routing'
import {useGlobalMaterialsQuery, useMaterialsQuery} from '../hooks'

import {MaterialDetails} from './MaterialDetails'
import {MaterialsStructure} from './materials-tree/MaterialsStructure'
import {SelectedMaterialProvider} from './SelectedMaterialProvider'

export const PageMaterialSettings: React.FC = () => {
  const {t} = useTranslation()
  const {data: materials} = useMaterialsQuery()
  const {data: latestData} = useLatestQuery()
  const {data: globalMaterialOptions} = useGlobalMaterialsQuery()

  if (!materials || !latestData || !globalMaterialOptions) {
    return <SettingsSkeleton />
  }

  return (
    <PageLayout
      title={t('materialsSettings.materials')}
      {...dataTestId(`page_${RouteName.MATERIALS_SETTINGS}`)}
    >
      <SettingsHelmet title={t('materialsSettings.pageTitle')} />
      <SelectedMaterialProvider materials={materials}>
        <LayoutSettings
          list={
            <MaterialsStructure
              materials={materials}
              globalMaterialOptions={globalMaterialOptions}
            />
          }
          details={<MaterialDetails />}
        />
      </SelectedMaterialProvider>
    </PageLayout>
  )
}
