import {StationLayout} from '../../kmsStatus'

import {AssetPartLocation} from './KilnModel.types'
import {
  Bearing,
  LocationPill,
  Roller,
  StationDeflection,
  StationTyre,
  ThrustRoller
} from './KilnParts'

export enum RollerParts {
  SUPPORT_ROLLER_BEARING_LEFT = 'support-roller_bearing-left',
  SUPPORT_ROLLER_DEFLECTOR = 'support-roller_deflector',
  SUPPORT_ROLLER_BEARING_RIGHT = 'support-roller_bearing-right',
  SUPPORT_ROLLER_ROLLER = 'support-roller_roller'
}

export enum StationParts {
  SUPPORT_ROLLER_TOP = 'top',
  SUPPORT_ROLLER_BOTTOM = 'bottom',
  TYRE = 'tyre',
  THRUST_ROLLER = 'thrust-roller'
}

export const buildStationLocations = ({
  id,
  thrustRollerPosition
}: Pick<StationLayout, 'id' | 'thrustRollerPosition'>) => {
  const locations: AssetPartLocation[] = [
    {
      id: `${id}_${StationParts.SUPPORT_ROLLER_TOP}_${RollerParts.SUPPORT_ROLLER_BEARING_LEFT}`,
      label: 'Bearing',
      locationNumber: 1,
      x: 0,
      y: 52
    },
    {
      id: `${id}_${StationParts.SUPPORT_ROLLER_TOP}_${RollerParts.SUPPORT_ROLLER_DEFLECTOR}`,
      label: 'Deflection',
      locationNumber: 2,
      x: 50,
      y: 0
    },
    {
      id: `${id}_${StationParts.SUPPORT_ROLLER_TOP}_${RollerParts.SUPPORT_ROLLER_BEARING_RIGHT}`,
      label: 'Bearing',
      locationNumber: 3,
      x: 100,
      y: 52
    },
    {
      id: `${id}_${StationParts.SUPPORT_ROLLER_TOP}_${RollerParts.SUPPORT_ROLLER_ROLLER}`,
      label: 'Roller',
      locationNumber: 4,
      x: 50,
      y: 50
    },
    {
      id: `${id}_${StationParts.TYRE}`,
      label: 'Tyre',
      locationNumber: 5,
      x: 50,
      y: 140
    },
    {
      id: `${id}_${StationParts.SUPPORT_ROLLER_BOTTOM}_${RollerParts.SUPPORT_ROLLER_ROLLER}`,
      label: 'Roller',
      locationNumber: 6,
      x: 50,
      y: 230
    },
    {
      id: `${id}_${StationParts.SUPPORT_ROLLER_BOTTOM}_${RollerParts.SUPPORT_ROLLER_BEARING_LEFT}`,
      label: 'Bearing',
      locationNumber: 7,
      x: 0,
      y: 228
    },
    {
      id: `${id}_${StationParts.SUPPORT_ROLLER_BOTTOM}_${RollerParts.SUPPORT_ROLLER_DEFLECTOR}`,
      label: 'Deflection',
      locationNumber: 8,
      x: 50,
      y: 280
    },
    {
      id: `${id}_${StationParts.SUPPORT_ROLLER_BOTTOM}_${RollerParts.SUPPORT_ROLLER_BEARING_RIGHT}`,
      label: 'Bearing',
      locationNumber: 9,
      x: 100,
      y: 228
    }
  ]

  if (thrustRollerPosition === 'right') {
    locations.push({
      id: `${id}_${StationParts.THRUST_ROLLER}`,
      label: 'Thrust Unit',
      locationNumber: 10,
      x: 5 + 92,
      y: 140
    })
  } else if (thrustRollerPosition === 'left') {
    locations.push({
      id: `${id}_${StationParts.THRUST_ROLLER}`,
      label: 'Thrust Unit',
      locationNumber: 10,
      x: 5,
      y: 140
    })
  }

  locations.sort((a, b) => a.locationNumber - b.locationNumber)
  return locations
}

const renderLocationPills = ({id, pillOffsetX, thrustRollerPosition}) => {
  const locationPills = buildStationLocations({id, thrustRollerPosition})

  return locationPills.map((pill) => (
    <LocationPill
      key={pill.locationNumber}
      x={pill.x + pillOffsetX}
      y={pill.y}
      text={pill.locationNumber}
    />
  ))
}

export const StationPart = ({
  id,
  x,
  y,
  width,
  height,
  thrustRollerPosition,
  showLocation = false
}) => {
  const hasThrustRoller = thrustRollerPosition !== 'none'
  const stationOffsetX = thrustRollerPosition === 'left' ? 41 : 0
  const thrustRollerOffsetX = thrustRollerPosition === 'right' ? 50 : 0
  const offsetY = showLocation ? 30 : 0
  const pillOffsetX = showLocation && thrustRollerPosition === 'left' ? 12 : 0
  const stationOffsetXPill = showLocation && thrustRollerPosition !== 'left' ? 30 : 0

  return (
    <svg id={id} x={x} y={y} width={width} height={height}>
      <svg x={stationOffsetX + stationOffsetXPill} y={offsetY}>
        <StationRoller idPrefix={`${id}_${StationParts.SUPPORT_ROLLER_TOP}`} y={0} position="top" />
        <StationRoller
          idPrefix={`${id}_${StationParts.SUPPORT_ROLLER_BOTTOM}`}
          y={194}
          position="bottom"
        />
        <StationTyre id={`${id}_${StationParts.TYRE}`} x={14} y={38} />
      </svg>
      {hasThrustRoller && (
        <ThrustRoller
          id={`${id}_${StationParts.THRUST_ROLLER}`}
          x={thrustRollerOffsetX + stationOffsetXPill}
          y={92 + offsetY}
        />
      )}
      {showLocation && renderLocationPills({id, pillOffsetX, thrustRollerPosition})}
    </svg>
  )
}

export const StationRoller = ({
  idPrefix,
  y,
  position
}: {
  idPrefix: string
  y: number
  position: 'top' | 'bottom'
}) => {
  const yOffset = position === 'top' ? 18 : 0
  const deflectorY = position === 'top' ? 0 : 34

  return (
    <svg width="68" height="50" viewBox="0 0 68 50" y={y}>
      <StationDeflection
        id={`${idPrefix}_${RollerParts.SUPPORT_ROLLER_DEFLECTOR}`}
        x="28"
        y={deflectorY}
      />
      <Bearing
        id={`${idPrefix}_${RollerParts.SUPPORT_ROLLER_BEARING_LEFT}`}
        x="0"
        y={8 + yOffset}
      />
      <Bearing
        id={`${idPrefix}_${RollerParts.SUPPORT_ROLLER_BEARING_RIGHT}`}
        x="34"
        y={8 + yOffset}
      />
      <Roller id={`${idPrefix}_${RollerParts.SUPPORT_ROLLER_ROLLER}`} x={12} y={yOffset} />
    </svg>
  )
}
