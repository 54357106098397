import {MaterialType, NewMaterialType} from '@hconnect/common/types'

export type UrlParams = Record<
  'plantCode' | 'page' | '*' | 'assetType' | 'assetId',
  string | undefined
>

export type SearchParams = typeof searchParams
export const searchParams = {
  materialType: [...Object.values(MaterialType), ...Object.values(NewMaterialType)],
  measurementTypesCategory: [] as string[]
}
