import {dataTestId, useTranslation} from '@hconnect/uikit'
import {CardBox, Loader} from '@hconnect/uikit/src/lib2'
import {LayersOutlined as LayersOutlinedIcon} from '@mui/icons-material/'
import {Box, CardContent, Collapse, Stack, TextField, Typography} from '@mui/material'
import React, {useState} from 'react'

import {MonthsFilterOptions} from '../../enums'
import {useResourceDetails} from '../../hooks/useResourceDetails'
import {Group} from '../../types'

import {ResourceCardHeader} from './ResourceCardHeader'

export const ResourceCardMobileView = ({
  selectedResource,
  selectedDate
}: {
  selectedResource: Group
  selectedDate?: string
}) => {
  const [open, setOpen] = useState<boolean>(false)

  const {
    data: resources,
    isLoading,
    isFetching
  } = useResourceDetails(selectedResource?.id, MonthsFilterOptions.Month, selectedDate)

  const handleToggle = () => {
    setOpen(!open)
  }

  const {t} = useTranslation()

  if (isLoading || isFetching) {
    return (
      <CardBox>
        <Loader />
      </CardBox>
    )
  }

  if (!resources) {
    return (
      <CardBox>
        <ResourceCardHeader title={selectedResource.name} handleToggle={handleToggle} open={open} />
        <Collapse in={open} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography>{t('janusConfig.dailyEntry.noData')}</Typography>
          </CardContent>
        </Collapse>
      </CardBox>
    )
  }

  return (
    <CardBox width="100%">
      <ResourceCardHeader
        title={selectedResource.name}
        handleToggle={handleToggle}
        open={open}
        isMobile
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <CardContent>
          <Stack gap={2}>
            {resources.map((report) => {
              return (
                <Box
                  key={report.specificKpiId}
                  display="flex"
                  flexDirection="row"
                  gap={1}
                  alignItems="center"
                >
                  <TextField
                    {...dataTestId(`value-text-field-${report.specificKpiId}`)}
                    fullWidth
                    value={report.values[report.values.length - 1].value || '-'}
                    label={report.name}
                    type="number"
                    variant="filled"
                  />
                  <LayersOutlinedIcon fontSize="small" />
                  <Typography>{report.values[0].version}</Typography>
                </Box>
              )
            })}
          </Stack>
        </CardContent>
      </Collapse>
    </CardBox>
  )
}
