import {dataTestId} from '@hconnect/uikit'
import {Alert, NumberLetterSequenceIndicator} from '@hconnect/uikit/src/lib2'
import {DeleteOutlined, Done} from '@mui/icons-material'
import {Stack, TextField, Autocomplete, Typography, Divider} from '@mui/material'
import React, {Fragment} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {useConfirmDialog} from '../../../../common/providers'
import {submitOnBlurAndEnterProps} from '../../../../common/utils'
import {usePermission} from '../../../../permissions'
import {useMaterialsQuery} from '../../../hooks'
import {
  useAddLimsMaterialToMaterial,
  useDeleteLimsMaterialFromMaterial
} from '../../../hooks/mutations/lims-materials'
import {LimsMaterial, Material, NewLimsMaterial} from '../../../types'
import {getMaterialWithAssignedLimsMaterial, getLimsMaterialDisplayName} from '../helpers'

import {DeleteButton} from '@settings/modules/common/components'
import {useUrlParam} from '@settings/modules/routing'

interface LimsMaterialFormProps {
  index: number
  limsMaterial: LimsMaterial | NewLimsMaterial
  setNewLimsMaterial: (limsMaterial?: NewLimsMaterial) => void
  limsMaterialOptions: LimsMaterial[]
  material: Material
}

interface LimsMaterialFormDefaultValues {
  limsMaterial: LimsMaterial | NewLimsMaterial
}

export const LimsMaterialForm: React.FC<LimsMaterialFormProps> = ({
  index,
  limsMaterial,
  setNewLimsMaterial,
  limsMaterialOptions,
  material
}) => {
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')
  const canChangeLimsMateialsMapping = usePermission('CHANGE_MATERIALS')
  const {openDialog} = useConfirmDialog()
  const {data: materials, isLoading: areMaterialsLoading} = useMaterialsQuery()

  const {mutate: addLimsMaterialToMaterial, isLoading: isLimsMaterialBeingAdded} =
    useAddLimsMaterialToMaterial()
  const {mutate: deleteLimsMaterialFromMaterial, isLoading: isLimsMaterialBeingDeleted} =
    useDeleteLimsMaterialFromMaterial()

  const isNewLimsMaterial = limsMaterial.id === undefined

  const isLimsMaterialDeletingDisabled =
    !canChangeLimsMateialsMapping ||
    areMaterialsLoading ||
    isLimsMaterialBeingAdded ||
    isLimsMaterialBeingDeleted

  const isLimsMaterialEditingDisabled = isLimsMaterialDeletingDisabled || !isNewLimsMaterial

  // Declaring form for Lims Material
  const {control, handleSubmit, reset} = useForm<LimsMaterialFormDefaultValues>({
    mode: 'onChange',
    defaultValues: {limsMaterial}
  })

  // Handler for user adding a new Lims Material
  const handleMapLimsMaterialToMaterial = handleSubmit(({limsMaterial: {id, code, name}}) => {
    if (id === undefined || materials === undefined) return

    const mapLimsMaterialToMaterial = () =>
      addLimsMaterialToMaterial(
        {plantCode, materialId: material.id, limsMaterialId: id},
        {
          onSuccess: () => setNewLimsMaterial(undefined),
          onError: () => reset()
        }
      )

    const conflictingMaterial = getMaterialWithAssignedLimsMaterial(materials, id)
    if (conflictingMaterial) {
      openDialog({
        mainAction: {
          text: t('materialsSettings.assignLimsMaterial'),
          icon: <Done />,
          onAction: mapLimsMaterialToMaterial
        },
        title: t('common.conflict'),
        description: t('materialsSettings.limsMaterialAlreadyAssignedDescription', {
          limsMaterialDisplayName: getLimsMaterialDisplayName(code, name),
          materialName: conflictingMaterial.name
        }),
        onCancel: () => {
          setNewLimsMaterial(undefined)
        },
        additionalContent: (
          <>
            <Alert
              accentColor="error"
              content={t('materialsSettings.limsMaterialAlreadyAssignedAdditionalInfo', {
                currentMaterialName: material.name,
                conflictingMaterialName: conflictingMaterial.name
              })}
            />
            <Typography variant="body1" sx={{mt: 2}}>
              {t('common.whatDoYouWantToDo')}
            </Typography>
          </>
        ),
        testId: 'conflict_lims_material_code_already_assigned'
      })
    } else {
      mapLimsMaterialToMaterial()
    }
  })

  // Handler for user deleting an existing Lims Material
  const handleDelete = () => {
    const limsMaterialId = limsMaterial.id
    if (limsMaterialId === undefined) setNewLimsMaterial(undefined)
    else {
      openDialog({
        mainAction: {
          text: t('common.delete'),
          color: 'error',
          icon: <DeleteOutlined />,
          onAction: () => {
            deleteLimsMaterialFromMaterial(
              {
                plantCode,
                materialId: material.id,
                limsMaterialId: limsMaterialId
              },
              {onError: () => reset()}
            )
          }
        },
        title: t('materialsSettings.deleteLimsMaterial'),
        description: t('materialsSettings.deleteLimsMaterialConfirmation', {
          limsMaterialDisplayName: getLimsMaterialDisplayName(limsMaterial.code, limsMaterial.name)
        })
      })
    }
  }

  return (
    <Stack spacing={3} direction="row" alignItems="center" {...dataTestId('lims_material_form')}>
      <NumberLetterSequenceIndicator
        numberIndex={index}
        {...dataTestId('lims_material_form_order_number')}
      />
      <Controller
        name="limsMaterial"
        control={control}
        render={({field: {value, ref, onChange}}) => (
          <Autocomplete
            freeSolo
            forcePopupIcon={true}
            sx={{width: (theme) => theme.spacing(44)}}
            options={limsMaterialOptions}
            value={value}
            disabled={isLimsMaterialEditingDisabled}
            onChange={(_, data) => onChange(data)}
            disableClearable={true}
            {...submitOnBlurAndEnterProps(handleMapLimsMaterialToMaterial)}
            renderInput={(params) => (
              <TextField
                {...params}
                inputRef={ref}
                label={t('materialsSettings.limsMaterial')}
                variant={isNewLimsMaterial ? 'filled' : 'outlined'}
                sx={{
                  '& .MuiFilledInput-root': {
                    backgroundColor: 'white',
                    fontWeight: 500
                  }
                }}
                {...dataTestId('lims_material_form_input')}
              />
            )}
            getOptionLabel={(option) => {
              return typeof option === 'string'
                ? option
                : getLimsMaterialDisplayName(option.code, option.name)
            }}
            renderOption={(props, option) => {
              return (
                <Fragment key={option.id}>
                  <Stack
                    {...props}
                    component="li"
                    spacing={0.1}
                    sx={{
                      '&.MuiAutocomplete-option': {
                        alignItems: 'flex-start'
                      }
                    }}
                  >
                    {option.name && <Typography variant="subtitle1">{option.name}</Typography>}
                    <Typography variant={option.name ? 'caption' : 'subtitle1'}>
                      {option.code}
                    </Typography>
                  </Stack>
                  <Divider component="li" variant="middle" />
                </Fragment>
              )
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            {...dataTestId('lims_material_form_autocomplete')}
          />
        )}
      />
      <DeleteButton
        sx={{ml: 2}}
        onClick={() => handleDelete()}
        disabled={isLimsMaterialDeletingDisabled}
        {...dataTestId('lims_material_form_delete_button')}
      />
    </Stack>
  )
}
