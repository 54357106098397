import {HPCommentItem} from '@hconnect/uikit/src/lib2/components/comment'
import {Stack} from '@mui/material'
import moment from 'moment'

import {EventType} from '../../enums'
import {Event} from '../../types'

import {EventVersion} from './EventVersion'

export const KpiComment = ({event}: {event: Event}) => {
  const {userName, value, eventDate, eventType, version, id} = event

  return (
    <Stack gap={1} sx={{cursor: 'pointer'}}>
      <HPCommentItem
        userName={userName}
        commentDate={moment.utc(eventDate).local()}
        title={''}
        content={
          eventType === EventType.Comment ? value : <EventVersion version={version} value={value} />
        }
        data-test-id={`kpi-comment-${id}`}
      />
    </Stack>
  )
}
