import type {AssetResponse} from '@hconnect/common/types'
import {Status, MaterialType, NewMaterialType} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {
  DeleteOutlined,
  DeleteOutline,
  ExpandLess,
  ExpandMore,
  DriveFileMoveOutlined
} from '@mui/icons-material'
import {ListItemSecondaryAction, TextField, MenuItem} from '@mui/material'
import React, {useRef} from 'react'
import {NodeApi} from 'react-arborist'
import {useTranslation} from 'react-i18next'

import {MaterialApiKeys} from '../../../../api/mutations'
import {Feature} from '../../../common/enums/enums'
import {useSettingsFeature} from '../../../common/hooks/useSettingsFeature'
import type {Schedule} from '../../../common/types'
import {MaterialTreeNode, isSelectedMaterial} from '../../types'
import type {MaterialWithRecipes} from '../../types'
import {useSelectedMaterial} from '../SelectedMaterialProvider'

import {MaterialDeleteInfo} from './MaterialDeleteInfo'
import {MaterialMoveInfo} from './MaterialMoveInfo'

import {
  findAllOperationModes,
  useAssetsQuery,
  filterProductionAssetsWithAnyRecipeFromList
} from '@settings/modules/assets'
import {KebabMenu} from '@settings/modules/common/components'
import {useScheduleQuery} from '@settings/modules/common/hooks'
import {useConfirmDialog} from '@settings/modules/common/providers'
import {
  useDeleteMaterial,
  useMaterialWithRecipesQuery,
  useEditMaterial
} from '@settings/modules/materials/hooks'
import {usePermission} from '@settings/modules/permissions'
import {useUrlParam} from '@settings/modules/routing'

interface MaterialsTreeNodeActionsProps {
  node: NodeApi<MaterialTreeNode>
}

export const MaterialsTreeNodeActions: React.FC<MaterialsTreeNodeActionsProps> = ({node}) => {
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')
  const canChangeMaterials = usePermission('CHANGE_MATERIALS')
  const {openDialog} = useConfirmDialog()
  const {setSelectedMaterial} = useSelectedMaterial()
  const isNewMaterialFeatureAvailable = useSettingsFeature(Feature.NewMaterialType)

  // using ref here to share state between uncontrolled select from dialog with action callback
  const movedMaterialRef = useRef<HTMLSelectElement>()

  const {mutate: editMaterial} = useEditMaterial()
  const {mutate: deleteMaterial, isLoading: isMaterialDeleting} = useDeleteMaterial()

  const originalEntity = node.data.originalEntity
  const isMaterialNode = isSelectedMaterial(originalEntity)
  const isNewMaterialNode = isMaterialNode && originalEntity.status === Status.New
  const selectedMaterialId = isMaterialNode && !isNewMaterialNode ? Number(node.data.id) : undefined

  const {data: assets} = useAssetsQuery()
  const {data: schedule} = useScheduleQuery()
  const {data: materialWithRecipes} = useMaterialWithRecipesQuery(selectedMaterialId)

  const getMaterialTypesList = (excludeType?: MaterialType) => {
    return Object.values(isNewMaterialFeatureAvailable ? NewMaterialType : MaterialType)
      .filter((type) => type !== excludeType)
      .map((type) => (
        <MenuItem key={type} value={type} {...dataTestId(`${type}_select_item`)}>
          {t(`materialsSettings.materialTypes.${type}`)}
        </MenuItem>
      ))
  }

  const openMoveMaterialNotPossibleDialog = (
    material: MaterialWithRecipes,
    assets: AssetResponse[]
  ) => {
    openDialog({
      title: t('materialsSettings.moveMaterialNotPossible'),
      additionalContent: <MaterialMoveInfo material={material} assets={assets} />,
      showCancelButton: false,
      mainAction: {
        text: t('common.ok')
      }
    })
  }

  const openDeleteMaterialDialog = (
    schedule: Schedule,
    assets: AssetResponse[],
    material: MaterialWithRecipes
  ) => {
    const operationModes = findAllOperationModes(assets, material.recipes)
    const scheduleItems = Object.values(schedule.schedules).filter(
      (item) => operationModes.find((mode) => mode.id === item.assetOperationModeId) !== undefined
    )
    openDialog({
      title: t('materialsSettings.deleteMaterial'),
      mainAction: {
        color: 'error',
        text: t('common.delete'),
        icon: <DeleteOutline />,
        onAction: () =>
          deleteMaterial(
            {plantCode, materialId: material.id},
            {
              onSuccess: () => {
                setSelectedMaterial(undefined)
              }
            }
          )
      },
      additionalContent: (
        <MaterialDeleteInfo
          material={material}
          operationModes={operationModes}
          scheduleItems={scheduleItems}
        />
      )
    })
  }

  const onMoveMaterialClick = (
    assets: AssetResponse[],
    materialWithRecipes: MaterialWithRecipes
  ) => {
    const assetsThatUseAnyRecipe = filterProductionAssetsWithAnyRecipeFromList(
      assets,
      materialWithRecipes.recipes
    )
    if (assetsThatUseAnyRecipe.length > 0) {
      openMoveMaterialNotPossibleDialog(materialWithRecipes, assetsThatUseAnyRecipe)
      return
    }

    openDialog({
      title: t('materialsSettings.moveMaterial'),
      description: t('materialsSettings.moveMaterialDescription'),
      mainAction: {
        text: t('common.move'),
        onAction: () => {
          if (movedMaterialRef.current?.value) {
            const newMaterialType = movedMaterialRef.current.value as MaterialType
            editMaterial({
              key: MaterialApiKeys.type,
              plantCode,
              name: materialWithRecipes.name,
              source: materialWithRecipes.source,
              materialId: materialWithRecipes.id,
              type: newMaterialType
            })
          }
        }
      },
      additionalContent: (
        <TextField
          inputRef={movedMaterialRef}
          defaultValue={Object.values(MaterialType).find(
            (type) => type !== materialWithRecipes.type
          )}
          variant="outlined"
          fullWidth
          select
          label={t('materialsSettings.materialType')}
          {...dataTestId('dialog_material_type_select')}
        >
          {getMaterialTypesList(materialWithRecipes.type)}
        </TextField>
      )
    })
  }

  const materialTypeActions = node.isOpen ? (
    <ExpandLess sx={{color: ({palette}) => palette.primary.main}} />
  ) : (
    <ExpandMore sx={{color: ({palette}) => palette.primary.main}} />
  )

  const materialActions = (
    <ListItemSecondaryAction>
      <KebabMenu
        id={`${`settings-tree-item-${isMaterialNode ? node.data.name : originalEntity}`}-menu`}
        actions={
          isNewMaterialNode
            ? [
                {
                  icon: <DeleteOutlined fontSize="small" />,
                  title: t('common.delete'),
                  testId: 'cancel_adding_new_material',
                  isDisabled: isMaterialDeleting || !canChangeMaterials,
                  onClick: () => {
                    setSelectedMaterial(undefined)
                  }
                }
              ]
            : node.isSelected && schedule && assets && materialWithRecipes
              ? [
                  {
                    icon: <DriveFileMoveOutlined fontSize="small" />,
                    title: t('common.move'),
                    testId: 'move_material',
                    isDisabled: !canChangeMaterials,
                    onClick: () => onMoveMaterialClick(assets, materialWithRecipes)
                  },
                  {
                    icon: <DeleteOutlined fontSize="small" color="error" />,
                    title: t('common.delete'),
                    testId: 'delete_material',
                    isDisabled: isMaterialDeleting || !canChangeMaterials,
                    onClick: () => openDeleteMaterialDialog(schedule, assets, materialWithRecipes)
                  }
                ]
              : []
        }
      />
    </ListItemSecondaryAction>
  )

  return isMaterialNode ? materialActions : materialTypeActions
}
