import ErrorIcon from '@mui/icons-material/ErrorOutline'
import WarningIcon from '@mui/icons-material/WarningAmber'
import {MenuItem, Stack, Collapse} from '@mui/material'
import useDebounce from '@react-hook/debounce'
import {useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {ControlledTextField, Thresholds, ControlledAutocomplete} from '../../../common/components'
import {ItemDetailsLayout} from '../../../janus/components/PlantStructure/ItemDetailsLayout'
import {Equipment} from '../../types/equipment'
import {SignalFormData, SignalDetail, SignalSource} from '../../types/signal'

import {parseSignalFormData, parseEquipmentToAutocompleteOptions} from './SignalForm.helpers'

import {ControlledAsyncAutocomplete} from '@settings/modules/common/components/form/ControlledAsyncAutocomplete'
import {useUrlParam} from '@settings/modules/routing'
import {useGetSignalNames} from '@settings/modules/upm/components/SignalForm/api/useGetSiginalNames'

interface SignalFormProps {
  defaultValues: SignalFormData
  equipment: Equipment[]
  units: {id: string; label: string}[]
  signalTypes: {id: string; label: string}[]
  onSubmit: (data: SignalDetail) => Promise<any>
  onGoBack: () => void
  steppedSetup?: boolean
}

export const SignalForm = ({
  defaultValues,
  equipment,
  units,
  signalTypes,
  onSubmit,
  onGoBack,
  steppedSetup = false
}: SignalFormProps) => {
  const {t} = useTranslation()
  const [showAllFields, setShowAllFields] = useState(!steppedSetup)

  const {
    handleSubmit,
    control,
    watch,
    getValues,
    setValue,
    reset,
    formState: {isDirty, isSubmitting}
  } = useForm({
    defaultValues,
    shouldFocusError: false
  })

  const [searchTerm, setSearchTerm] = useDebounce<string | null>(
    defaultValues?.localName ?? null,
    500
  )
  const plantCode = useUrlParam('plantCode')
  const signalSource = watch('source')
  const {
    data: signalNames,
    isLoading: isLoadingNames,
    isError,
    isSuccess
  } = useGetSignalNames(plantCode, searchTerm, signalSource)

  const showLocalName = !steppedSetup || !!watch('source')
  const watchLocalName = watch('localName')

  useEffect(() => {
    // Prevent clear value when default values are sed
    if (searchTerm !== defaultValues?.localName) {
      setValue('localName', null)
      setSearchTerm('')
    }
  }, [signalSource, setValue, setSearchTerm])

  useEffect(() => {
    if (showAllFields) return
    if (watchLocalName && (isSuccess || isError)) {
      setShowAllFields(true)
    }
  }, [isError, isSuccess, showAllFields, watchLocalName])

  const equipmentOptions = useMemo(
    () => parseEquipmentToAutocompleteOptions(equipment),
    [equipment]
  )
  const equipmentPath = getValues('equipment')?.path

  const submit = async (data: SignalFormData) => {
    if (isDirty && !isSubmitting) {
      try {
        await onSubmit(parseSignalFormData(data))
        reset(data)
      } catch (e) {
        console.error(e)
        reset(data, {keepDirty: true})
      }
    }
  }

  const statusOptions = useMemo(
    () => [
      {
        value: 'alarm',
        label: t('thresholdTable.label.alarm'),
        Icon: <ErrorIcon fontSize="small" color="error" />
      },
      {
        value: 'warning',
        label: t('thresholdTable.label.warning'),
        Icon: <WarningIcon fontSize="small" color="warning" />
      }
    ],
    [t]
  )

  return (
    <ItemDetailsLayout
      hideNav
      title={t('upmSettings.signalDetails')}
      titleAction={{
        label: t('upmSettings.backToList'),
        onClick: onGoBack
      }}
      saveButton={{
        hide: false,
        onClick: handleSubmit(submit),
        disabled: isSubmitting || !isDirty,
        ButtonProps: {
          loadingPosition: 'start',
          loading: isSubmitting
        }
      }}
      revertButton={{
        hide: false,
        onClick: () => {
          reset(defaultValues)
          setShowAllFields(!steppedSetup)
        },
        disabled: isSubmitting || !isDirty
      }}
      CardBoxProps={{sx: {minWidth: '630px'}}}
    >
      <Stack spacing={4} minWidth="630px">
        <Stack sx={{maxWidth: '500px'}} spacing={2.5}>
          <ControlledTextField
            formDataName="source"
            label="Source"
            control={control}
            rules={{required: 'This field is required'}}
            TextFieldProps={{select: true}}
            onChangeCb={() => setValue('localName', defaultValues.localName)}
          >
            <MenuItem value={SignalSource.PXTREND}>PxTrend</MenuItem>
            <MenuItem value={SignalSource.DALOG}>Dalog</MenuItem>
            <MenuItem value={SignalSource.MACHINE_SENTRY}>Machine Sentry</MenuItem>
            <MenuItem value={SignalSource.SCHAEFFLER}>Schaeffler</MenuItem>
          </ControlledTextField>
          <Collapse in={showLocalName}>
            <ControlledAsyncAutocomplete
              formDataName="localName"
              label="Local ID"
              control={control}
              rules={{required: 'This field is required'}}
              options={signalNames ?? []}
              stringOnly
              onAddCustomValue={(value) => {
                setSearchTerm(value)
                setValue('localName', value)
              }}
              loading={isLoadingNames}
            />
          </Collapse>
          <Collapse in={showAllFields}>
            <Stack spacing={2.5}>
              <ControlledTextField formDataName="name" label="Signal Name" control={control} />
              <ControlledTextField
                formDataName="description"
                label="Description"
                control={control}
              />
              <ControlledAutocomplete
                formDataName="type"
                label="Signal Type"
                control={control}
                rules={{required: 'This field is required'}}
                options={signalTypes}
              />
              <ControlledAutocomplete
                formDataName="unit"
                label="Unit"
                control={control}
                options={units}
                rules={{required: 'This field is required'}}
              />
              <ControlledAutocomplete
                formDataName="equipment"
                label="Equipment"
                control={control}
                options={equipmentOptions}
                rules={{required: 'This field is required'}}
                helperText={equipmentPath}
              />
              {equipmentPath && <span></span>}
            </Stack>
          </Collapse>
        </Stack>
        <Collapse in={showAllFields}>
          <Thresholds
            title="Plant Thresholds"
            control={control}
            getValues={getValues}
            thresholdsConfig={plantThresholdsConfig}
            setValue={setValue}
            statusOptions={statusOptions}
            CardBoxProps={{p: 0, boxShadow: 0}}
          />
        </Collapse>
      </Stack>
    </ItemDetailsLayout>
  )
}

const plantThresholdsConfig = [
  {
    label: 'High High',
    valueName: 'thresholds.highHigh',
    statusName: 'thresholds.highHighStatus',
    unit: null,
    disabled: false,
    defaultStatus: 'alarm'
  },
  {
    label: 'High',
    valueName: 'thresholds.high',
    statusName: 'thresholds.highStatus',
    unit: null,
    disabled: false,
    defaultStatus: 'warning'
  },
  {
    label: 'Low',
    valueName: 'thresholds.low',
    statusName: 'thresholds.lowStatus',
    unit: null,
    disabled: false,
    defaultStatus: 'warning'
  },
  {
    label: 'Low Low',
    valueName: 'thresholds.lowLow',
    statusName: 'thresholds.lowLowStatus',
    unit: null,
    disabled: false,
    defaultStatus: 'alarm'
  }
] as const

// CURRENTLY NOT USED
// const customThresholdsConfig = [
//   {
//     label: 'Custom High',
//     valueName: 'customThresholds.high',
//     statusName: 'customThresholds.highStatus',
//     timeToBreachName: null,
//     unit: null,
//     disabled: false,
//     defaultStatus: 'warning'
//   },
//   {
//     label: 'Model Confidence',
//     valueName: 'modelConfidence.value',
//     statusName: 'modelConfidence.status',
//     timeToBreachName: null,
//     unit: '%',
//     disabled: true,
//     defaultStatus: 'warning'
//   },
//   {
//     label: 'Custom Low',
//     valueName: 'customThresholds.low',
//     statusName: 'customThresholds.lowStatus',
//     timeToBreachName: null,
//     unit: null,
//     disabled: false,
//     defaultStatus: 'warning'
//   }
// ] as const
