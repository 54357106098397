import {MaterialStorage} from '@hconnect/common/types'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Radio
} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {StructureNodeUnist} from '@settings/modules/common/types'

const columns = [
  'select',
  'isAttached',
  'name',
  'materialType',
  'measurementType',
  'optimized',
  'isMeasured',
  'capacity',
  'deadStock',
  'minimumStockLevel',
  'minimumWeekendStockLevel',
  'slackCostPerTone',
  'unitOfMeasurement',
  'currentStorageLevel'
]

interface MaterialStoragesProps {
  materialStorages?: MaterialStorage[]
  setSelectedStorageId: React.Dispatch<React.SetStateAction<number | undefined>>
  selectedStorageId?: number
  nodesById?: Record<string, StructureNodeUnist>
}

export const MaterialStorages: React.FC<MaterialStoragesProps> = ({
  materialStorages,
  selectedStorageId,
  setSelectedStorageId,
  nodesById
}) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const {t} = useTranslation()

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleSelectionChange = (storageId: number) => {
    setSelectedStorageId(storageId)
  }

  const sortedStorages =
    materialStorages &&
    materialStorages.sort((a, b) => {
      if (a.materialType < b.materialType) return -1
      if (a.materialType > b.materialType) return 1

      if (a.name < b.name) return -1
      if (a.name > b.name) return 1

      return 0
    })

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={`material-storage-column-${column}`}>
                  {t(`janusConfig.plantSetup.${column}`)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedStorages
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((storage) => (
                <TableRow key={storage.id}>
                  <TableCell>
                    <Radio
                      checked={selectedStorageId === storage.id}
                      onChange={() => handleSelectionChange(storage.id)}
                    />
                  </TableCell>
                  <TableCell>
                    {storage.upmId && nodesById
                      ? t('janusConfig.plantSetup.yesAttachedTo', {
                          name: nodesById[storage.upmId].data.name
                        })
                      : t('janusConfig.plantSetup.no')}
                  </TableCell>
                  <TableCell>{storage.name}</TableCell>
                  <TableCell>{storage.materialType}</TableCell>
                  <TableCell>{storage.measurementType}</TableCell>
                  <TableCell>
                    {storage.isOptimized
                      ? t('janusConfig.plantSetup.yes')
                      : t('janusConfig.plantSetup.no')}
                  </TableCell>
                  <TableCell>
                    {storage.isMeasured
                      ? t('janusConfig.plantSetup.yes')
                      : t('janusConfig.plantSetup.no')}
                  </TableCell>
                  <TableCell>{storage.capacity}</TableCell>
                  <TableCell>{storage.deadStock}</TableCell>
                  <TableCell>{storage.minimumStockLevel}</TableCell>
                  <TableCell>{storage.minimumWeekendStockLevel}</TableCell>
                  <TableCell>{storage.slackCostPerTonne}</TableCell>
                  <TableCell>{storage.unitOfMeasurement}</TableCell>
                  <TableCell>{storage.currentStorageLevel.level}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={materialStorages?.length ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}
