import {useMemo} from 'react'

import {AutocompleteOption, Material} from '../../../types'

export const useMaterialsWithGlobalName = (
  materials: Material[],
  globalMaterialOptions: AutocompleteOption[]
) =>
  useMemo(
    () =>
      materials.map((material) => ({
        ...material,
        ...(material.globalMaterialId && {
          globlaMaterialName: globalMaterialOptions.find(
            (option) => option.id === material.globalMaterialId!.toString()
          )?.label
        })
      })),
    [globalMaterialOptions, materials]
  )
