import ICAL from 'ical.js'
import moment, {Moment} from 'moment-timezone'
import {tzlib_get_ical_block} from 'timezones-ical-library'

import {
  StandardOperationTime,
  StandartOperationTimeEnds,
  StandartOperationTimeRRule,
  StandartOperationTimeRepetition
} from '../types/standardOperationTimes'

const biggestEndTime = '01.00:00'

export type StandardOperationParserParams = {
  timezone_id: string
}

/**
 * formats time from format '00.01:30' to 'hh:mm'
 * @param time should be in timespan format
 */
export const formatDayTimeSpan = (time: string) =>
  time === biggestEndTime ? '24:00' : time.split('00.')[1]

const convertICalFrequencyValueToInternal = (freq: ICAL.FrequencyValues) => {
  if (freq === 'DAILY') {
    return StandartOperationTimeRepetition.DAILY
  }
  if (freq === 'WEEKLY') {
    return StandartOperationTimeRepetition.WEEKLY
  }
  if (freq === 'MONTHLY') {
    return StandartOperationTimeRepetition.MONTHLY
  }
  throw new Error('Invalid ICAL frequency value')
}

export const parseRRuleString = (
  {timezone_id}: StandardOperationParserParams,
  rruleString: string
): StandartOperationTimeRRule => {
  const recur = ICAL.Recur.fromString(rruleString)

  const values = recur.toJSON()

  let recurrenceRule: StandartOperationTimeRRule = {
    freq: convertICalFrequencyValueToInternal(recur.freq),
    interval: values.interval || 1 // Default to 1 if interval is not defined
  } as StandartOperationTimeRRule

  if (recur.freq === 'WEEKLY') {
    if (!values.byday) {
      throw new Error('No byday value in the recurrence rule')
    }
    const daysOfWeek: {[day: number]: boolean} = {}
    const weekDaysMap: {[key: string]: number} = {
      MO: 0,
      TU: 1,
      WE: 2,
      TH: 3,
      FR: 4,
      SA: 5,
      SU: 6
    }

    for (let i = 0; i < 7; i++) {
      daysOfWeek[i] = values.byday.includes(
        Object.keys(weekDaysMap).find((key) => weekDaysMap[key] === i) || ''
      )
    }

    recurrenceRule = {
      ...recurrenceRule,
      freq: StandartOperationTimeRepetition.WEEKLY,
      daysOfWeek
    }
  }

  if (recur.count) {
    recurrenceRule = {
      ...recurrenceRule,
      ends: StandartOperationTimeEnds.after,
      count: recur.count
    }
  } else if (recur.until) {
    recurrenceRule = {
      ...recurrenceRule,
      ends: StandartOperationTimeEnds.on,
      until: moment(recur.until.toJSDate()).tz(timezone_id)
    }
  }

  return recurrenceRule
}

const convertToRecur = (recurrenceRule: StandartOperationTimeRRule): ICAL.Recur => {
  const recurData: ICAL.RecurData = {
    freq: recurrenceRule.freq.toUpperCase() as ICAL.FrequencyValues,
    interval: recurrenceRule.interval
  }

  if (
    recurrenceRule.freq === StandartOperationTimeRepetition.WEEKLY &&
    'daysOfWeek' in recurrenceRule
  ) {
    const days = Object.keys(recurrenceRule.daysOfWeek)
      .filter((day) => recurrenceRule.daysOfWeek[day])
      .map((day) => {
        switch (parseInt(day, 10)) {
          case 0:
            return 'MO'
          case 1:
            return 'TU'
          case 2:
            return 'WE'
          case 3:
            return 'TH'
          case 4:
            return 'FR'
          case 5:
            return 'SA'
          case 6:
            return 'SU'
          default:
            return undefined
        }
      })
      .filter((day) => day !== undefined) as string[]

    if (days.length > 0) {
      recurData.byday = days
    }
  }

  if (recurrenceRule.ends === StandartOperationTimeEnds.after) {
    recurData.count = recurrenceRule.count
  } else if (recurrenceRule.ends === StandartOperationTimeEnds.on) {
    if (recurrenceRule.until) {
      recurData.until = ICAL.Time.fromJSDate(recurrenceRule.until.toDate(), true)
    } else {
      recurData.count = 1
    }
  }

  return new ICAL.Recur(recurData)
}

function getTimeZoneComponentFromDefinition(timezoneDefinition: string | string[]): ICAL.Component {
  let icalTzDefinition: string
  if (Array.isArray(timezoneDefinition)) {
    icalTzDefinition = timezoneDefinition[0]
  } else if (typeof timezoneDefinition === 'string') {
    icalTzDefinition = timezoneDefinition
  } else {
    throw new Error('Unrecognized ical definition type')
  }
  return ICAL.Component.fromString(icalTzDefinition)
}

function getTimezoneComponent(plantTimezoneId: string) {
  const timezoneDefinition = tzlib_get_ical_block(plantTimezoneId)
  if (!timezoneDefinition) {
    throw new Error(`System does not have timezone definition for ${plantTimezoneId}`)
  }
  return getTimeZoneComponentFromDefinition(timezoneDefinition)
}

function getTimezoneIdFromComponent(component: ICAL.Component): string {
  const ICALTzComponent = component.getFirstProperty('tzid')
  const parsedTzId = ICALTzComponent.getValues()[0] as string
  return parsedTzId
}

function checkIfTimezoneIsSupported(timezoneId: string) {
  return ICAL.TimezoneService.has(timezoneId)
}

function convertMomentToICALTime(moment: Moment): ICAL.Time {
  const isUtc = !!moment.tz()
  const icalTime = ICAL.Time.fromJSDate(
    moment.clone().utc().add(moment.utcOffset(), 'minutes').toDate(),
    isUtc
  )
  const originalTzId = moment.tz() ?? 'UTC'

  const timezoneComponent = getTimezoneComponent(originalTzId)
  // could be different from the original timezone if not supported
  const timezoneId = getTimezoneIdFromComponent(timezoneComponent)

  const zone = ICAL.TimezoneService.get(timezoneId)
  if (!zone) {
    // because ical.js does not by default have timezones, we need to register it
    ICAL.TimezoneService.register(timezoneComponent, timezoneId)
  }

  const tzObj = ICAL.TimezoneService.get(timezoneId)

  if (!tzObj) {
    throw new Error(`Cannot get timezone object for ${timezoneId}`)
  }
  icalTime.zone = tzObj

  return icalTime
}

function convertMomentToICALProperty(
  name: string,
  moment: Moment,
  overrideTimezone: string
): ICAL.Property {
  const time = convertMomentToICALTime(moment)
  const property = new ICAL.Property(name)
  // Make sure that BE gets the same timezone as the plant
  property.setParameter('tzid', overrideTimezone)
  property.setValue(time)
  return property
}

// function to send the ical string to the backend
export const generateICalString = (
  operationTime: StandardOperationTime,
  // forcing timezone for both start and end
  forcedTimezone: string
): string => {
  const event = new ICAL.Component('vevent')

  const eventStart = convertMomentToICALProperty('dtstart', operationTime.start, forcedTimezone)
  const eventEnd = convertMomentToICALProperty('dtend', operationTime.end, forcedTimezone)
  event.addProperty(eventStart)
  event.addProperty(eventEnd)

  event.addPropertyWithValue('summary', 'Standard Operation Time')

  const rruleString = convertToRecur(operationTime.recurrenceRule)
  event.addPropertyWithValue('rrule', rruleString)

  return event.toString()
}

function convertICALPropertyToMoment(property: ICAL.Property) {
  const tzProperty = property.getFirstParameter('tzid') ?? 'UTC'
  // parsing from BE to FE
  const timezoneComponent = getTimezoneComponent(tzProperty)
  const parsedTimezoneId = getTimezoneIdFromComponent(timezoneComponent)
  const isPlantTimezoneSupported = checkIfTimezoneIsSupported(tzProperty)
  const timezoneId = isPlantTimezoneSupported ? tzProperty : parsedTimezoneId
  const dateAsString: string = property.getFirstValue().toString()

  const year = parseInt(dateAsString.substring(0, 4))
  const month = parseInt(dateAsString.substring(5, 7)) - 1
  const date = parseInt(dateAsString.substring(8, 10))
  const hour = parseInt(dateAsString.substring(11, 13))
  const minute = parseInt(dateAsString.substring(14, 16))
  const second = parseInt(dateAsString.substring(17, 18))

  const milliseconds = 0

  const momentTime = moment.tz(timezoneId).set({
    year,
    month,
    date,
    hour,
    minute,
    second,
    milliseconds
  })
  return momentTime
}

export const parseICalString = (
  params: StandardOperationParserParams,
  icalString: string
): StandardOperationTime => {
  try {
    if (!icalString) {
      throw new Error('iCalendar event is empty.')
    }
    // Parse the iCalendar string
    const jcalData = ICAL.parse(icalString)
    if (!jcalData) {
      throw new Error('Error parsing calendar event.')
    }
    const vevent = new ICAL.Component(jcalData)

    // Extract the main event component
    if (!vevent) {
      throw new Error('Error parsing calendar event.')
    }

    // Extract start and end dates
    const dtstartProp = vevent.getFirstProperty('dtstart')
    if (!dtstartProp) {
      throw new Error('No DTSTART found in the iCalendar data.')
    }
    const dtendProp = vevent.getFirstProperty('dtend')
    if (!dtendProp) {
      throw new Error('No DTEND found in the iCalendar data.')
    }
    const start = convertICALPropertyToMoment(dtstartProp)
    const end = convertICALPropertyToMoment(dtendProp)

    // Extract RRULE if present
    const rruleString = vevent.getFirstPropertyValue('rrule')
    let recurrenceRule: StandartOperationTimeRRule

    if (rruleString) {
      // Convert RRULE string to custom format
      recurrenceRule = parseRRuleString(params, rruleString.toString())
    } else {
      throw new Error('No RRULE found in the iCalendar data.')
    }

    // Return the parsed data in custom format
    return {
      start,
      end,
      recurrenceRule: recurrenceRule
    }
  } catch (error) {
    console.error('Error parsing iCalendar string:', error, (error as any)?.stack)
    throw error
  }
}

export const getNextScheduledOccurences = ({
  standardOperationTime,
  now,
  timezone_id,
  count
}: {
  standardOperationTime: StandardOperationTime
  now: Moment
  timezone_id: string
  count: number
}): Moment[] => {
  const recur = convertToRecur(standardOperationTime.recurrenceRule)
  const startTime = standardOperationTime.start
  const occurrences: Moment[] = []
  const iterator = recur.iterator(
    startTime ? ICAL.Time.fromJSDate(startTime.toDate(), true) : undefined
  )

  while (occurrences.length < count) {
    const nextOccurrence = iterator.next()
    if (nextOccurrence && now.isSameOrBefore(nextOccurrence.toJSDate())) {
      occurrences.push(moment(nextOccurrence.toJSDate()).tz(timezone_id))
    }
    if (!nextOccurrence) {
      break // No more occurrences
    }
  }

  return occurrences
}
