import {HttpError} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {useQuery} from 'react-query'

import {KilnConfigDto} from '../../types/assetConfig'

import {Api} from '@settings/api'
import {useUrlParam} from '@settings/modules/routing'

export const QueryKey = 'asset-config'

export const useGetAssetConfig = (assetId: string) => {
  const plantCode = useUrlParam('plantCode')
  return useQuery<KilnConfigDto, AxiosError<HttpError>>(
    [QueryKey, plantCode, assetId],
    async () => {
      const path = `asset-config/kms-data/plant/${plantCode}?upmType=Department&typeUpmId=${assetId}`
      return (await Api.axiosInstance.get<KilnConfigDto>(path)).data
    },
    {
      useErrorBoundary: (error) => error?.response?.status !== 404,
      retry: (retryCount, error) => error?.response?.status !== 404 && retryCount < 3,
      onError: () => null
    }
  )
}
