import {dataTestId, useTranslation} from '@hconnect/uikit'
import {Check as CheckIcon} from '@mui/icons-material'
import {Box, Button, Typography} from '@mui/material'

import {SaveButtonState} from '../../enums'

export const DailyEntryHeader = ({
  selectedDate,
  onSaveClick,
  saveButtonState,
  onApproveClick,
  canRangeBeApproved
}: {
  selectedDate: string | undefined
  onSaveClick?: () => void
  onApproveClick?: () => void
  saveButtonState: SaveButtonState
  canRangeBeApproved?: boolean
}) => {
  const {t} = useTranslation()

  const color = saveButtonState === SaveButtonState.Saved ? 'success' : 'primary'
  const text =
    saveButtonState === SaveButtonState.Saved
      ? t('janusConfig.dailyEntry.saved')
      : t('janusConfig.dailyEntry.save')

  const testId = `daily-entry-save-button-${saveButtonState}`

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      {...dataTestId('selected-date-container')}
    >
      <Typography variant="h3">{selectedDate}</Typography>
      <Box display="flex" flexDirection="row" gap={2}>
        {saveButtonState !== SaveButtonState.Hidden && (
          <Button
            sx={{
              '&.Mui-disabled': {
                backgroundColor: 'rgba(1, 106, 212, 0.4)',
                color: 'white',
                '.MuiButton-startIcon': {
                  color: 'red !important'
                },
                '.MuiButton-iconSizeMedium': {
                  color: 'red !important'
                }
              }
            }}
            color={color}
            startIcon={<CheckIcon />}
            variant="contained"
            {...dataTestId(testId)}
            onClick={onSaveClick}
            disabled={saveButtonState === SaveButtonState.Disabled}
          >
            {text}
          </Button>
        )}
        <Button
          sx={{
            '&.Mui-disabled': {
              color: 'white',
              '.MuiButton-startIcon': {
                color: 'red !important'
              },
              '.MuiButton-iconSizeMedium': {
                color: 'red !important'
              }
            }
          }}
          variant="contained"
          {...dataTestId('approve-button-test-id')}
          onClick={onApproveClick}
          disabled={!canRangeBeApproved}
        >
          {t('janusConfig.dailyEntry.approve')}
        </Button>
      </Box>
    </Box>
  )
}
