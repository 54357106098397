import {MaterialType, NewMaterialType, Status} from '@hconnect/common/types'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {Feature} from '../../../../common/enums/enums'
import {useSettingsFeature} from '../../../../common/hooks/useSettingsFeature'
import {Material, SelectedMaterial, MaterialTreeNode, MaterialTree} from '../../../types'

export const useMaterialsTreeData = (
  materials: Material[],
  selectedMaterial?: SelectedMaterial
) => {
  const {t} = useTranslation()
  const isNewMaterialFeatureAvailable = useSettingsFeature(Feature.NewMaterialType)

  return useMemo<MaterialTree>(() => {
    const materialTypeToMaterials = materials.reduce((mapping, material) => {
      const materialArboristNode: MaterialTreeNode = {
        id: material.id.toString(),
        name: material.name,
        originalEntity: material
      }
      if (mapping[material.type]) {
        mapping[material.type].push(materialArboristNode)
      } else {
        mapping[material.type] = [materialArboristNode]
      }
      return mapping
    }, {})

    const materialsTreeData = Object.values(
      isNewMaterialFeatureAvailable ? NewMaterialType : MaterialType
    ).map<MaterialTreeNode>((type) => ({
      id: type,
      name: t(`materialsSettings.materialTypes.${type}`),
      originalEntity: type,
      children: materialTypeToMaterials[type] ?? []
    }))

    if (selectedMaterial && selectedMaterial.status === Status.New)
      materialsTreeData.unshift({
        id: 'New Material',
        name: t('materialsSettings.newMaterial'),
        originalEntity: selectedMaterial
      })

    return materialsTreeData
  }, [isNewMaterialFeatureAvailable, materials, selectedMaterial, t])
}
