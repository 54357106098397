import {Status} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Divider, ListItemButton, ListItemText, Box} from '@mui/material'
import React, {useEffect} from 'react'
import {NodeRendererProps} from 'react-arborist'

import {MaterialTreeNode, isSelectedMaterial} from '../../types'
import {useSelectedMaterial} from '../SelectedMaterialProvider'

import {MaterialsTreeNodeActions} from './MaterialsTreeNodeActions'

export const MaterialsTreeNode: React.FC<NodeRendererProps<MaterialTreeNode>> = ({node, style}) => {
  const {setSelectedMaterial} = useSelectedMaterial()

  const originalEntity = node.data.originalEntity
  const isMaterialNode = isSelectedMaterial(originalEntity)
  const isNewMaterialNode = isMaterialNode && originalEntity.status === Status.New

  const onTreeNodeClick = (e: React.MouseEvent) => {
    if (isMaterialNode && !isNewMaterialNode) {
      setSelectedMaterial(originalEntity)
    } else {
      node.toggle()
      // We use stopPropagation to not remove selection from the leaf node
      e.stopPropagation()
    }
  }

  useEffect(() => {
    // If we have a new material node, we need to select it
    if (isNewMaterialNode && !node.isSelected) node.select()
  }, [isNewMaterialNode, node])

  return (
    <Box {...dataTestId(`settings-tree-${isMaterialNode ? 'leaf' : 'internal'}`)}>
      <ListItemButton
        selected={isMaterialNode && node.isSelected}
        onClick={onTreeNodeClick}
        style={style}
        sx={{height: '58px'}}
        {...dataTestId(`settings-tree-item-${isMaterialNode ? node.data.name : originalEntity}`)}
      >
        <ListItemText
          primary={node.data.name}
          secondary={isMaterialNode && !isNewMaterialNode && originalEntity.globlaMaterialName}
          sx={{pl: 1.5}}
        />
        <MaterialsTreeNodeActions node={node} />
      </ListItemButton>
      <Divider />
    </Box>
  )
}
