import {MaterialType, Status, MaterialSource, ProductType} from '@hconnect/common/types'

import {RecipeComponentType, ProductForecastStatus} from '../../common/enums'

type MaterialBEStatus = Status.Created | Status.Edited
export interface Product {
  id: number
  code: string
  name: string
  type: ProductType
}

export type ProductForecastInfo = {
  status: ProductForecastStatus
}

export type ProductsForecastInfo = Record<string, ProductForecastInfo>

export type MaterialsProducts = Record<ProductType, Product[]>

interface ComponentMaterial {
  id: number
  name: string
  pxTrendCounters?: string[]
}

export interface RecipeComponent {
  id: number
  type: RecipeComponentType
  fraction: number
  material: ComponentMaterial
}

export interface RecipeComponentDTO {
  materialId: number
  fraction: number
  type: RecipeComponentType
}

export interface Recipe {
  id: number
  name: string
  mainMaterialId: number
  status: MaterialBEStatus
  components: RecipeComponent[]
}

export interface RecipeDTO {
  name: string
}

export interface NewRecipe extends RecipeDTO {
  status: Status.New
  mainMaterialId: number
  components: RecipeComponent[]
}

export interface Material extends ComponentMaterial {
  createdBy: string
  createdOn: string
  status: MaterialBEStatus
  type: MaterialType
  source: MaterialSource
  products: Product[]
  globalMaterialId?: number
  globlaMaterialName?: string
  limsMaterials: LimsMaterial[]
}

export interface MaterialDTO {
  type: MaterialType
  name: string
  source: MaterialSource
  pxTrendCounters: string[]
  globalMaterialId?: number
}

export type NewMaterial = Omit<MaterialDTO, 'type'> & {
  status: Status.New
  type?: MaterialType
}

export type SelectedMaterial = Material | NewMaterial
export interface MaterialWithRecipes extends Material {
  recipes: Recipe[]
}

export interface GlobalMaterial {
  id: number
  name: string
  type: MaterialType
}

export interface AutocompleteOption {
  id: string
  label: string
}

export interface LimsMaterial {
  id: number
  code: string
  name?: string
}

export type NewLimsMaterial = Pick<LimsMaterial, 'code' | 'name'> & {id?: number}

// Generic settings tree types
export type SettingsTreeNode<T extends MaterialTreeComponents> = {
  id: string
  name: string
  originalEntity: T
  children?: SettingsTree<T>
}
export type SettingsTree<T extends MaterialTreeComponents> = ReadonlyArray<SettingsTreeNode<T>>

// Material settings tree types
type MaterialTreeComponents = MaterialType | SelectedMaterial
export type MaterialTreeNode = SettingsTreeNode<MaterialTreeComponents>
export type MaterialTree = SettingsTree<MaterialTreeComponents>

export const isSelectedMaterial = (
  originalEntity: MaterialTreeComponents
): originalEntity is SelectedMaterial => (originalEntity as SelectedMaterial).status !== undefined
