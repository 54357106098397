import {dataTestId} from '@hconnect/uikit'
import {TextField, Stack} from '@mui/material'
import {debounce} from 'lodash'
import {useTranslation} from 'react-i18next'

import {QuickFilterOptionButton} from '@settings/modules/common/components/settingsTree'

interface MaterialsTreeFiltersProps {
  treeQuickFilter: FilterOptions
  setTreeQuickFilter: (quickFilter: FilterOptions) => void
  setTreeFilterText: (text: string) => void
}

export enum FilterOptions {
  All = 'All',
  Produced = 'ProducedInPlant',
  Received = 'BoughtFromVendor'
}

export const MaterialsTreeFilters: React.FC<MaterialsTreeFiltersProps> = ({
  treeQuickFilter,
  setTreeQuickFilter,
  setTreeFilterText
}) => {
  const {t} = useTranslation()
  const debouncedSetFilterText = debounce((text: string) => setTreeFilterText(text), 300)
  return (
    <>
      <Stack direction="row" spacing={2} sx={{mb: '12px'}}>
        {Object.values(FilterOptions).map((filterOption) => (
          <QuickFilterOptionButton
            key={filterOption}
            data-selected={filterOption === treeQuickFilter}
            variant="outlined"
            onClick={() => setTreeQuickFilter(filterOption)}
            {...dataTestId(`settings-tree-quick-filter-${filterOption}`)}
          >
            {t(`materialsSettings.filterOptions.${filterOption}`)}
          </QuickFilterOptionButton>
        ))}
      </Stack>
      <TextField
        fullWidth
        label={t('materialsSettings.filter')}
        variant="filled"
        onChange={(e) => debouncedSetFilterText(e.target.value)}
        sx={{
          '& .MuiFilledInput-root': {
            backgroundColor: 'white'
          }
        }}
        {...dataTestId('settings-tree-text-filter')}
      />
    </>
  )
}
