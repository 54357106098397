import {dataTestId} from '@hconnect/uikit'
import {CardBox, Loader} from '@hconnect/uikit/src/lib2'
import {LayersOutlined as LayersOutlinedIcon} from '@mui/icons-material/'
import {
  Box,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Collapse,
  Typography
} from '@mui/material'
import moment from 'moment'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {MonthsFilterOptions} from '../../enums'
import {useResourceDetails} from '../../hooks/useResourceDetails'
import {Group} from '../../types'

import {ResourceCardHeader} from './ResourceCardHeader'

export const ResourceCard = ({
  selectedResource,
  periodType,
  selectedDate
}: {
  selectedResource: Group
  periodType: MonthsFilterOptions
  selectedDate?: string
}) => {
  const {
    data: resources,
    isLoading,
    isFetching
  } = useResourceDetails(selectedResource?.id, periodType, selectedDate)

  const {t} = useTranslation()

  const [open, setOpen] = useState<boolean>(false)

  const handleToggle = () => {
    setOpen(!open)
  }

  if (isLoading || isFetching) {
    return (
      <CardBox>
        <Loader />
      </CardBox>
    )
  }

  if (!resources) {
    return (
      <CardBox>
        <ResourceCardHeader title={selectedResource.name} handleToggle={handleToggle} open={open} />
        <Collapse in={open} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography>{t('janusConfig.dailyEntry.noData')}</Typography>
          </CardContent>
        </Collapse>
      </CardBox>
    )
  }

  return (
    <CardBox>
      <ResourceCardHeader title={selectedResource.name} handleToggle={handleToggle} open={open} />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('janusConfig.monthlyEntry.dataField')}</TableCell>{' '}
                {resources[0].values.map((value) => (
                  <TableCell
                    key={`${value.date}-${resources[0].specificKpiId}`}
                    {...dataTestId(
                      `table-head-cell-date-${value.date}-${resources[0].specificKpiId}`
                    )}
                  >
                    {moment(value.date).format('MMMM YYYY').toString()}
                  </TableCell>
                ))}
                <TableCell>{''}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resources.map((report, index) => (
                <TableRow key={`report-${index}`}>
                  <TableCell
                    key={`cell-name-${report.name}`}
                    {...dataTestId(`cell-name-${report.name}`)}
                  >
                    {report.name}
                  </TableCell>
                  {report.values.map((value) => (
                    <TableCell
                      {...dataTestId(`report-kpi-value-cell-${report.specificKpiId}-${value.date}`)}
                      key={`${report.specificKpiId}-${value.date}`}
                    >
                      {value.value || '-'}
                    </TableCell>
                  ))}
                  <TableCell>
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <LayersOutlinedIcon fontSize="small" />{' '}
                      {report.values[report.values.length - 1].version}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Collapse>
    </CardBox>
  )
}
