export const ROUTES = {
  BASE: {
    path: '/',
    PLANT_SELECTOR: {
      path: '/plants',
      PLANT: {
        path: '/plants/:plantCode',
        STORAGES: {
          path: '/plants/:plantCode/storages'
        },
        ASSETS: {
          path: '/plants/:plantCode/assets',
          ASSET_TYPE: {
            path: '/plants/:plantCode/assets/:assetType',
            ID: {
              path: '/plants/:plantCode/assets/:assetType/id',
              ASSET_ID: {
                path: '/plants/:plantCode/assets/:assetType/id/:assetId'
              }
            },
            NEW_ASSET: {
              path: '/plants/:plantCode/assets/:assetType/new'
            }
          }
        },
        MATERIALS: {
          path: '/plants/:plantCode/materials'
        },
        KPI_CALCULATION: {
          path: '/plants/:plantCode/kpi'
        },
        SAMPLING_POINTS: {
          path: '/plants/:plantCode/samplingPoints'
        },
        MEASUREMENTS: {
          path: '/plants/:plantCode/measurements'
        },
        PLANT_SETUP: {
          relPath: 'plantSetup',
          path: '/plants/:plantCode/janus/plantSetup'
        },
        ENTRY_DAILY: {
          relPath: 'dailyEntry',
          path: '/plants/:plantCode/janus/dailyEntry'
        },
        ENTRY_MONTHLY: {
          path: '/plants/:plantCode/janus/monthlyEntry',
          relPath: 'monthlyEntry'
        },
        STANDARD_REPORTS: {
          path: '/plants/:plantCode/janus/standardReports',
          relPath: 'standardReports'
        },
        JANUS_CONFIG: {
          path: '/plants/:plantCode/janus',
          relPath: 'janus'
        }
      }
    },
    NOT_AUTHORIZED: {
      path: '/403'
    },
    NOT_FOUND: {
      path: '/404'
    }
  }
} as const

export const PLANT_ROUTES = ROUTES.BASE.PLANT_SELECTOR.PLANT

export enum RouteName {
  NOT_AUTHORIZED = 'notAuthorized',
  NOT_FOUND = 'notFound',
  SETTINGS = 'settings',
  STORAGES_SETTINGS = 'storagesSettings',
  ASSETS_SETTINGS = 'assetsSettings',
  MATERIALS_SETTINGS = 'materialsSettings',
  KPI_CALCULATION = 'kpiCalculationSettings',
  MEASUREMENTS = 'measurementsSettings',
  SAMPLING_POINTS = 'samplingPointsSettings',
  JANUS_CONFIG = 'janusConfig',
  PLANT_SETUP = 'plantSetup',
  ENTRY_DAILY = 'dailyEntry',
  ENTRY_MONTHLY = 'monthlyEntry',
  STANDARD_REPORTS = 'standardReports'
}

export const ROUTES_PERMISSIONS = {
  MEASUREMENTS: 'VIEW_MATERIALS',
  SAMPLING_POINTS: 'VIEW_MATERIALS'
} as const

/**
 * Main settings routes in order with permissions
 */
export const mainRoutes = [
  {
    name: RouteName.STORAGES_SETTINGS,
    permissionType: 'VIEW_MATERIAL_STORAGE',
    path: PLANT_ROUTES.STORAGES.path
  },
  {
    name: RouteName.ASSETS_SETTINGS,
    permissionType: 'VIEW_ASSETS',
    path: PLANT_ROUTES.ASSETS.path
  },
  {
    name: RouteName.MATERIALS_SETTINGS,
    permissionType: 'VIEW_MATERIALS',
    path: PLANT_ROUTES.MATERIALS.path
  },
  {
    name: RouteName.SAMPLING_POINTS,
    permissionType: ROUTES_PERMISSIONS.SAMPLING_POINTS,
    path: PLANT_ROUTES.SAMPLING_POINTS.path
  },
  {
    name: RouteName.MEASUREMENTS,
    permissionType: ROUTES_PERMISSIONS.MEASUREMENTS,
    path: PLANT_ROUTES.MEASUREMENTS.path
  },
  {
    name: RouteName.JANUS_CONFIG,
    permissionType: 'READ_JANUS_PLANT_CONFIG',
    path: PLANT_ROUTES.JANUS_CONFIG.path
  },
  {
    name: RouteName.ENTRY_DAILY,
    permissionType: 'READ_JANUS_PLANT_DATA',
    path: PLANT_ROUTES.PLANT_SETUP.path
  },
  {
    name: RouteName.ENTRY_MONTHLY,
    permissionType: 'READ_JANUS_PLANT_DATA',
    path: PLANT_ROUTES.ENTRY_MONTHLY.path
  },
  {
    name: RouteName.STANDARD_REPORTS,
    permissionType: 'READ_JANUS_PLANT_DATA',
    path: PLANT_ROUTES.PLANT_SETUP.path
  },
  {
    name: RouteName.PLANT_SETUP,
    permissionType: 'READ_JANUS_PLANT_CONFIG',
    path: PLANT_ROUTES.PLANT_SETUP.path
  }
] as const
