import {dataTestId} from '@hconnect/uikit'
import {Loader} from '@hconnect/uikit/src/lib2'
import {InfoOutlined} from '@mui/icons-material'
import {Stack, Box, Tooltip, TextField, Typography, Grid} from '@mui/material'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {useAssets} from '../../../../hooks/useAssets'
import {useMaterialStorages} from '../../../../hooks/useMaterialStorages'
import {useSelectedItem} from '../../../SelectedItemProvider'

import {Switch} from '@settings/modules/common/components'
import {CopyableTextField} from '@settings/modules/common/components/form/CopyableTextField'

export const MappingInfo = () => {
  const {t} = useTranslation()

  const {data: assets, isLoading: isLoadingAssets, isFetching: isFetchingAssets} = useAssets()
  const {
    data: materialStorages,
    isLoading: isLoadingStorages,
    isFetching: isFetchingStorages
  } = useMaterialStorages()

  const {selectedItem} = useSelectedItem()

  const isLoadingOrFetching =
    isFetchingAssets || isFetchingStorages || isLoadingAssets || isLoadingStorages

  const mappedAssets = useMemo(
    () => assets?.filter((item) => item.upmId === selectedItem?.data.id) || [],
    [assets, selectedItem?.data.id]
  )

  const mappedStorages = useMemo(
    () => materialStorages?.filter((item) => item.upmId === selectedItem?.data.id) || [],
    [materialStorages, selectedItem?.data.id]
  )

  if (isLoadingOrFetching) {
    return <Loader />
  }

  if (!selectedItem) {
    return null
  }

  return (
    <Stack spacing={3}>
      {mappedAssets?.map((asset) => (
        <Stack key={asset.id} spacing={2}>
          <Typography variant="h3">{t('janusConfig.plantSetup.assetMapping')}</Typography>
          <Stack direction="row" spacing={2}>
            <TextField
              label={t('assetsSettings.assetName')}
              sx={{width: ({spacing}) => spacing(50)}}
              value={asset.name}
              disabled
            />
            <CopyableTextField label={t('assetsSettings.assetId')} value={asset.id.toString()} />
          </Stack>
          <TextField
            label={t('assetsSettings.minimumDowntime')}
            sx={{width: ({spacing}) => spacing(50)}}
            value={asset.minimumDowntime}
            disabled
            inputProps={{step: '1', min: '0', max: '1000'}}
          />
          <TextField
            sx={{width: ({spacing}) => spacing(50)}}
            label={t('assetsSettings.startUpCosts')}
            value={asset.startupCost}
            disabled
            onChange={() => {}}
          />
          <Stack direction="row" spacing={2}>
            <TextField
              sx={{width: ({spacing}) => spacing(24)}}
              label={t('assetsSettings.energyCoefficientStart')}
              value={asset.startCoefficient}
              disabled
              onChange={() => {}}
            />
            <TextField
              sx={{width: ({spacing}) => spacing(24)}}
              label={t('assetsSettings.energyCoefficientStop')}
              onChange={() => {}}
              disabled
              value={asset.stopCoefficient}
            />
            <Tooltip title={t('assetsSettings.energyCoefficientTooltip')}>
              <Box sx={{display: 'flex', alignItems: 'center'}}>
                <InfoOutlined sx={{fontSize: 'inherit'}} />
              </Box>
            </Tooltip>
          </Stack>
          <Switch
            value={asset.isOptimized}
            label={t('assetsSettings.optimized')}
            disabled
            {...dataTestId('is_optimized_asset_switch')}
          />
          <Switch
            value={asset.isShutdownAvailable}
            disabled
            label={t('assetsSettings.shutdownAvailability')}
          />
        </Stack>
      ))}
      {mappedStorages.map((storage) => (
        <Grid key={storage.id} spacing={2} sx={{width: (theme) => theme.spacing(56)}} container>
          <Typography variant="h3">{t('janusConfig.plantSetup.storageMapping')}</Typography>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              disabled
              label={t('storagesSettings.storageName')}
              value={storage.name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label={t('storagesSettings.storedMaterial')}
              value={storage.materialType ?? ''}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField label={t('storagesSettings.capacity')} value={storage.capacity} disabled />
          </Grid>
          <Grid item xs={6}>
            <TextField label={t('storagesSettings.deadStock')} value={storage.deadStock} disabled />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={t('storagesSettings.minStockLevel')}
              value={storage.minimumStockLevel}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={t('storagesSettings.minStockLevelWeekend')}
              value={storage.minimumWeekendStockLevel}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Switch label={t('storagesSettings.optimized')} value={storage.isOptimized} disabled />
          </Grid>
          <Grid item xs={12}>
            <Switch label={t('storagesSettings.measured')} value={storage.isMeasured} disabled />
          </Grid>
          {storage && storage.isMeasured && (
            <Grid item xs={12} sx={{mt: 4}}>
              <TextField
                fullWidth
                label={t('storagesSettings.unitOfMeasurement')}
                value={t(`storagesSettings.unitOfMeasurementItems.${storage.unitOfMeasurement}`)}
                disabled
              />
            </Grid>
          )}
        </Grid>
      ))}
    </Stack>
  )
}
