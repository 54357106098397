import {dataTestId} from '@hconnect/uikit'
import {Box, LinearProgress, Stack, Typography} from '@mui/material'
import React from 'react'

export const ProgressBar = ({
  progress,
  primaryText,
  secondaryText,
  testId,
  height,
  width,
  backgroundColor,
  textColor
}: {
  progress: number
  primaryText: string
  secondaryText: string
  testId: string
  height?: number
  width?: string
  backgroundColor: string
  textColor: string
}) => {
  return (
    <Stack {...dataTestId(testId)} sx={{width}} pb={2}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography variant="body2" sx={{color: textColor}}>
          {primaryText}
        </Typography>
        <Typography variant="body2">{secondaryText}</Typography>
      </Box>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          height,
          backgroundColor,
          color: 'primary'
        }}
      />
    </Stack>
  )
}
