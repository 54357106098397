import {useSettingsQuery} from '../../common/providers/ApiInterface'
import {useUrlParam} from '../../routing'

export const useMaterialStorages = () => {
  const plantCode = useUrlParam('plantCode')
  return useSettingsQuery('materialStorage', [plantCode], {
    cacheTime: 0,
    staleTime: 0
  })
}
