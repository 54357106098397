import {HttpError} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {useSnackbar} from 'notistack'
import {useMutation} from 'react-query'

import {settingsQueryClient} from '../../../common/providers'
import {KilnConfigDto} from '../../types/assetConfig'

import {QueryKey as AssetConfigKey} from './useGetAssetConfig'

import {Api} from '@settings/api'
import {useUrlParam} from '@settings/modules/routing'

export const useCreateKilnConfig = () => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()
  const plantCode = useUrlParam('plantCode')

  return useMutation(
    async (kilnConfig: KilnConfigDto) => {
      const path = `asset-config/kms-data/plant/${plantCode}?upmType=Department&typeUpmId=${kilnConfig.metadata.typeUpmId}`
      return (await Api.axiosInstance.post(path, {...kilnConfig.payload})).data
    },
    {
      onSuccess: (kilnConfig: KilnConfigDto) => {
        settingsQueryClient.setQueryData(
          [AssetConfigKey, plantCode, kilnConfig.metadata.typeUpmId],
          kilnConfig
        )
        enqueueSnackbar('Kiln config created successfully', {
          variant: 'success',
          autoHideDuration: 2500,
          onClick: () => closeSnackbar()
        })
      },
      onError: (error: AxiosError<HttpError>) => {
        enqueueSnackbar(`Kiln config could not be created: ${error.message}`, {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          onClick: () => closeSnackbar()
        })
      }
    }
  )
}
