import moment from 'moment'

import {usePermission} from '../../permissions'
import {getCanDataBeApproved} from '../helpers/getCanDataBeApproved'

export const useCanApproveData = (isSingleDayView: boolean, selectedDate: string | undefined) => {
  return (
    usePermission('EDIT_JANUS_PLANT_DATA') &&
    getCanDataBeApproved(moment(), isSingleDayView, selectedDate)
  )
}
