import React, {createContext, useContext, useState, ReactNode} from 'react'

interface ItemLinkContextProps {
  itemToLink: string | null
  setItemToLink: (item: string | null) => void
}

const ItemLinkContext = createContext<ItemLinkContextProps | undefined>(undefined)

export const ItemLinkProvider: React.FC<{children: ReactNode}> = ({children}) => {
  const [itemToLink, setItemToLink] = useState<string | null>(null)

  return (
    <ItemLinkContext.Provider value={{itemToLink, setItemToLink}}>
      {children}
    </ItemLinkContext.Provider>
  )
}

export const useItemLink = (): ItemLinkContextProps => {
  const context = useContext(ItemLinkContext)
  if (!context) {
    throw new Error('useItemLink must be used within an ItemLinkProvider')
  }
  return context
}
