import {visitParents} from 'unist-util-visit-parents'

import {useSettingsQuery} from '@settings/modules/common/providers'
import {
  StructureNode,
  StructureNodeUnist,
  StructureNodeUnistWithAncestors
} from '@settings/modules/common/types'
import {useUrlParam} from '@settings/modules/routing'

const getUnistNodes = (
  node: StructureNode
): {tree: StructureNodeUnist; flatNodes: StructureNodeUnistWithAncestors[]} => {
  const flatNodes: StructureNodeUnistWithAncestors[] = []

  const transformToUnistNode = (node: StructureNode): StructureNodeUnist => {
    return {
      type: node.entityType,
      data: {
        id: node.id,
        upmId: node.upmId,
        name: node.name,
        signalCount: node.signalCount,
        entityProperties: node.entityProperties
      },
      children: node.children ? node.children.map(transformToUnistNode) : [],
      entityType: node.entityType
    }
  }

  const tree = transformToUnistNode(node)

  visitParents(tree, (node, ancestors) => {
    flatNodes.push({
      ...node,
      ancestors
    })
  })

  return {
    tree,
    flatNodes
  }
}
export const useGetUnistPlantStructure = () => {
  const plantCode = useUrlParam('plantCode')
  return useSettingsQuery('upmStructure', [plantCode], {select: getUnistNodes, staleTime: Infinity})
}
