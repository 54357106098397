import {MaterialSource, Status} from '@hconnect/common/types'
import {Box} from '@mui/material'
import {useCallback} from 'react'
import {NodeApi, Tree} from 'react-arborist'
import useResizeObserver from 'use-resize-observer'

import {MaterialTreeNode, MaterialTree, isSelectedMaterial} from '../../types'

import {FilterOptions} from './MaterialsTreeFilters'
import {MaterialsTreeNode} from './MaterialsTreeNode'

interface MaterialsTreeProps {
  treeData: MaterialTree
  treeQuickFilter?: FilterOptions
  treeFilterText?: string
}

export const MaterialsTree: React.FC<MaterialsTreeProps> = ({
  treeData,
  treeQuickFilter,
  treeFilterText
}) => {
  const {ref: treeRef, width, height} = useResizeObserver()

  const treeFilterFunction = useCallback(
    (node: NodeApi<MaterialTreeNode>) => {
      const originalEntity = node.data.originalEntity
      const isMaterialNode = isSelectedMaterial(originalEntity)
      if (!isMaterialNode) return false

      if ((!treeFilterText && !treeQuickFilter) || originalEntity.status === Status.New) return true

      const materialName = originalEntity.name.toLowerCase()
      const globalMaterialName = originalEntity.globlaMaterialName?.toLowerCase()
      const materialSource = originalEntity.source

      const quickFilter =
        treeQuickFilter && treeQuickFilter !== FilterOptions.All
          ? materialSource === (treeQuickFilter as unknown as MaterialSource)
          : true

      const textFilter = treeFilterText
        ? materialName.includes(treeFilterText.toLowerCase()) ||
          (!!globalMaterialName && globalMaterialName.includes(treeFilterText.toLowerCase()))
        : true

      return quickFilter && textFilter
    },
    [treeFilterText, treeQuickFilter]
  )

  const treeSearchTerm =
    treeFilterText ||
    (treeQuickFilter && treeQuickFilter !== FilterOptions.All ? treeQuickFilter : undefined)

  return (
    <Box ref={treeRef} height={'65vh'}>
      <Tree
        data={treeData}
        openByDefault={false}
        disableDrag={true}
        disableDrop={true}
        height={height}
        width={width}
        rowHeight={58}
        indent={24}
        searchTerm={treeSearchTerm}
        searchMatch={treeFilterFunction}
      >
        {MaterialsTreeNode}
      </Tree>
    </Box>
  )
}
