/* eslint-disable complexity */
import {CardBox, Loader} from '@hconnect/uikit/src/lib2'

import {SignalDataType, SignalFormData, SignalSource, ThresholdStatus} from '../../types/signal'

import {useGetSignalDetail} from './api/useGetSignalDetail'
import {useUpdateSignal} from './api/useUpdateSignal'
import {useSignalOptions} from './hooks/useSignalOptions'
import {SignalForm} from './SignalForm'
import {getEquipmentPath} from './SignalForm.helpers'

export const EditSignal = ({
  signalId,
  onGoBack
}: {
  signalId: string | null
  onGoBack: () => void
}) => {
  if (!signalId) throw new Error('Ilegal route: signalId is required')

  const {equipment, signalTypes, units, optionsLoading} = useSignalOptions()
  const {data: signalData, isLoading: sensorLoading} = useGetSignalDetail(signalId)
  const {mutateAsync: updateSignal} = useUpdateSignal(signalId)

  const isLoading = optionsLoading || sensorLoading

  const signalEquipment = equipment?.find((equipment) => equipment.id === signalData?.equipmentId)
  const signalType = signalTypes?.find((type) => type.id === signalData?.typeId)
  const signalUnit = units?.find((unit) => unit.id === signalData?.unitId)

  const defaultValues: SignalFormData = {
    localName: signalData?.localName ?? '',
    source: signalData?.source ?? SignalSource.PXTREND,
    dataType: signalData?.dataType ?? SignalDataType.RAW,
    type: signalType ?? null,
    unit: signalUnit ?? null,
    equipment: signalEquipment
      ? {
          id: signalEquipment.id,
          label: signalEquipment.text ?? 'Unknown',
          path: getEquipmentPath(signalEquipment)
        }
      : null,
    name: signalData?.name ?? '',
    description: signalData?.description ?? '',
    customThresholds: {
      high: signalData?.customThresholds?.high ?? null,
      highStatus:
        (signalData?.customThresholds?.highStatus?.toLowerCase() as ThresholdStatus) ?? '',
      low: signalData?.customThresholds?.low ?? null,
      lowStatus: (signalData?.customThresholds?.lowStatus?.toLowerCase() as ThresholdStatus) ?? ''
    },
    modelConfidence: {
      value: signalData?.modelConfidence?.value ?? null,
      status: signalData?.modelConfidence?.status ?? ''
    },
    thresholds: {
      high: signalData?.thresholds?.high ?? null,
      highHigh: signalData?.thresholds?.highHigh ?? null,
      low: signalData?.thresholds?.low ?? null,
      lowLow: signalData?.thresholds?.lowLow ?? null,
      highStatus: (signalData?.thresholds?.highStatus?.toLowerCase() as ThresholdStatus) ?? '',
      lowStatus: (signalData?.thresholds?.lowStatus?.toLowerCase() as ThresholdStatus) ?? '',
      highHighStatus:
        (signalData?.thresholds?.highHighStatus?.toLowerCase() as ThresholdStatus) ?? '',
      lowLowStatus: (signalData?.thresholds?.lowLowStatus?.toLowerCase() as ThresholdStatus) ?? ''
    },
    timeToBreach: {
      high: signalData?.timeToBreach?.high ?? null,
      highHigh: signalData?.timeToBreach?.highHigh ?? null,
      low: signalData?.timeToBreach?.low ?? null,
      lowLow: signalData?.timeToBreach?.lowLow ?? null
    }
  }

  return isLoading ? (
    <CardBox>
      <Loader />
    </CardBox>
  ) : (
    <SignalForm
      defaultValues={defaultValues}
      equipment={equipment ?? []}
      units={units ?? []}
      signalTypes={signalTypes ?? []}
      onSubmit={updateSignal}
      onGoBack={onGoBack}
    />
  )
}
