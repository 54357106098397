import {AssetPartLocation, KilnShellLayout} from './KilnModel.types'
import {LocationPill} from './KilnParts'

export const buildKilnShellLocations = ({id}: Pick<KilnShellLayout, 'id'>) => {
  const locations: AssetPartLocation[] = [
    {
      id: `${id}_${KilnShellParts.KILN_SHELL}`,
      label: 'Kiln Shell',
      locationNumber: 1,
      x: 600,
      y: 40
    }
  ]

  locations.sort((a, b) => a.locationNumber - b.locationNumber)
  return locations
}

export enum KilnShellParts {
  KILN_SHELL = 'shell'
}

export const KilnShellPart = ({id, x, y, width, height, showLocation = false}: KilnShellLayout) => (
  <svg
    id={`${id}_${KilnShellParts.KILN_SHELL}`}
    x={x}
    y={y}
    width={width}
    height={height}
    viewBox="0 0 1344 136"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width={1344}
      height={136}
      rx={8}
      fill="url(#paint0_linear_833_12744)"
      filter="url(#filter0_i_833_12744)"
    />
    <rect width={1344} height={136} rx={8} fill="black" fillOpacity={0.0} className="overlay" />
    <rect x={0.5} y={0.5} width={1343} height={135} rx={7.5} stroke="black" strokeOpacity={0.4} />
    {showLocation && <LocationPill x={600} y={40} text="1" width={50} height={50} />}
    <defs>
      <filter
        id="filter0_i_833_12744"
        x={0}
        y={0}
        width={1344}
        height={136}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius={2}
          operator="erode"
          in="SourceAlpha"
          result="effect1_innerShadow_833_12744"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={1} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_833_12744" />
      </filter>
      <linearGradient
        id="paint0_linear_833_12744"
        x1={605.5}
        y1={0}
        x2={605.5}
        y2={136}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#97A1A6" />
        <stop offset={0.519414} stopColor="#D6E1E6" />
        <stop offset={1} stopColor="#97A1A6" />
      </linearGradient>
    </defs>
  </svg>
)
