import {dataTestId} from '@hconnect/uikit'
import {Card, CardTitle} from '@hconnect/uikit/src/lib2'
import {Add} from '@mui/icons-material'
import {Box, Button} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {usePermission} from '../../../permissions'
import {Material} from '../../types'

import {PxTrendForm} from './PxTrendForm'

interface PxTrendCardProps {
  material: Material
}

export const PxTrendCard: React.FC<PxTrendCardProps> = ({material}) => {
  const {t} = useTranslation()
  const canChangeMaterials = usePermission('CHANGE_MATERIALS')

  const [hasNewPxTrendCounter, setHasNewPxTrendCounter] = useState<boolean>()

  const {pxTrendCounters = []} = material

  const attachedPxTrendCounters = [...pxTrendCounters, ...(hasNewPxTrendCounter ? [''] : [])]

  return (
    <Card
      headerContent={
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <CardTitle sx={{mb: 0}}>{t('materialsSettings.pxTrend')}</CardTitle>
          <Button
            variant="text"
            startIcon={<Add />}
            disabled={!canChangeMaterials || Boolean(hasNewPxTrendCounter)}
            onClick={() => setHasNewPxTrendCounter(true)}
            {...dataTestId('add_px_trend')}
          >
            {t('materialsSettings.addTag')}
          </Button>
        </Box>
      }
    >
      {attachedPxTrendCounters.map((pxTrendCounter, index) => (
        <PxTrendForm
          key={`${material.id}_${pxTrendCounter}_${index}`}
          index={index}
          pxTrendCounter={pxTrendCounter}
          material={material}
          isReadOnly={!canChangeMaterials}
          setHasNewPxTrendCounter={setHasNewPxTrendCounter}
        />
      ))}
    </Card>
  )
}
