import {dataTestId} from '@hconnect/uikit'
import {CardBox, Loader} from '@hconnect/uikit/src/lib2'
import {ChevronRight, ExpandMore, Add as AddIcon} from '@mui/icons-material'
import {
  Box,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField
} from '@mui/material'
import {styled} from '@mui/material/styles'
import {debounce} from 'lodash'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {NodeApi, NodeRendererProps, Tree} from 'react-arborist'
import {useTranslation} from 'react-i18next'
import {useSearchParams} from 'react-router-dom'
import useResizeObserver from 'use-resize-observer'

import {useEditAsset} from '../../../assets'
import {useGetUnistPlantStructure} from '../../../common/hooks/queries/usePlantStructureQuery'
import {
  EntityProperty,
  StructureNodeUnist,
  StructureNodeUnistWithAncestors,
  UpmEntityType
} from '../../../common/types'
import {useUrlParam} from '../../../routing'
import {useEditStorage} from '../../../storages/hooks'
import {useSelectedItem} from '../SelectedItemProvider'

import {AddSubdomainPopup} from './AddSubdomainPopup'
import {useItemLink} from './ItemLinkContext'
import {settingsQueryClient, useLoginDetails} from '../../../common/providers'

const StyledListItem = styled(ListItem)(() => ({
  '&:hover .icon-button': {
    visibility: 'visible'
  },
  '.icon-button': {
    visibility: 'hidden'
  }
}))
const allowedIds = [
  '6b8a54b2-cfa6-407c-8ad5-e88780152f10',
  '86f532f3-66d0-4545-88da-510ec1ad70e6',
  '6296fa10-24f2-4528-bfbd-468d08cb1d2d',
  '5264e312-4be4-4fa3-a9e6-e09723ffc709',
  '0ccfea22-d1fa-4b7f-bd13-9ffbb2f8690c',
  '4767a8df-a149-41b0-8784-73c01b586a8e'
]

const StyledListItemButton = styled(ListItemButton)(({theme}) => ({
  borderRadius: theme.shape.borderRadius
}))

const Node = <T extends ArboristNode>({node, style}: NodeRendererProps<T>) => {
  const shouldHideArrow = node.isLeaf || node.data.children?.length === 0

  const name =
    node.data.entityType === 'Area'
      ? `(${node.data.entityProperties?.code}) ${node.data.name}`
      : node.data.name

  const {setItemToLink} = useItemLink()

  const handlePlusClick = (event: React.MouseEvent) => {
    setItemToLink(node.id)
    event.stopPropagation()
  }

  const shouldShowAddSubdomain =
    node.data.entityType === 'Equipment' && node.data.addSubdomainAllowed
  return (
    <StyledListItem
      dense={true}
      disablePadding
      style={style}
      {...dataTestId(`list-item-${node.id}`)}
    >
      <StyledListItemButton
        sx={{
          backgroundColor: (theme) => (node.isSelected ? theme.palette.action.selected : 'inherit')
        }}
      >
        <ListItemIcon
          sx={{...(shouldHideArrow ? {display: 'none'} : {})}}
          onClick={(e) => {
            node.isInternal && node.toggle()
            e.stopPropagation()
          }}
        >
          {node.isOpen ? <ExpandMore /> : <ChevronRight />}
        </ListItemIcon>
        <ListItemText
          sx={{pl: !shouldHideArrow ? 4 : 0}}
          primaryTypographyProps={{noWrap: true}}
          primary={name}
        />
        {shouldShowAddSubdomain && (
          <IconButton className="icon-button" onClick={handlePlusClick}>
            <AddIcon />
          </IconButton>
        )}
      </StyledListItemButton>
    </StyledListItem>
  )
}

type ArboristNode = Readonly<{
  id: string
  name: string
  entityType: UpmEntityType
  children?: ReadonlyArray<ArboristNode>
  entityProperties?: EntityProperty
  addSubdomainAllowed: boolean
}>

const mapToArboristNode = (
  node: StructureNodeUnist,
  addSubdomainAllowed: boolean
): ArboristNode => ({
  id: node.data.id,
  name: node.data.name,
  entityType: node.entityType,
  entityProperties: node.data.entityProperties,
  addSubdomainAllowed,
  ...{children: node.children?.map((node) => mapToArboristNode(node, addSubdomainAllowed))}
})

export const PlantStructureTree = () => {
  const {t} = useTranslation()

  const [searchParams, setSearchParams] = useSearchParams()
  const {selectedItem, setSelectedItem} = useSelectedItem()
  const {mutateAsync: mutateAsset} = useEditAsset()
  const {mutateAsync: mutateMaterialStorage} = useEditStorage()

  const {itemToLink, setItemToLink} = useItemLink()
  const {data: plantStructure, isLoading} = useGetUnistPlantStructure()

  const {loginDetails} = useLoginDetails()
  const userId = loginDetails?.userId || ''

  const shouldShowAddSubdomain = useMemo(() => allowedIds.includes(userId), [userId])

  const nodesByUpmId = useMemo(
    () =>
      plantStructure?.flatNodes.reduce(
        (acc, node) => {
          acc[node.data.id] = node
          return acc
        },
        {} as Record<string, StructureNodeUnistWithAncestors>
      ),
    [plantStructure]
  )

  const upmNodeToLink = useMemo(
    () => (itemToLink && nodesByUpmId ? nodesByUpmId[itemToLink] : null),
    [itemToLink, nodesByUpmId]
  )

  const handleAssociateSubdomainsClick = (values: {
    assetId?: number
    storageId?: number
    upmId: string
  }) => {
    const assetMutation =
      values.assetId &&
      mutateAsset({
        plantCode,
        assetId: values.assetId,
        key: 'upmId',
        dto: {upmId: values.upmId}
      })

    const storageMutation =
      values.storageId &&
      mutateMaterialStorage({
        plantCode,
        id: values.storageId,
        key: 'upmId',
        dto: {upmId: values.upmId}
      })

    Promise.all([assetMutation, storageMutation, new Promise((r) => setTimeout(r, 3000))]).then(
      () => settingsQueryClient.invalidateQueries(['assetsGeneralInfo'], {exact: false})
    )
  }

  const plantCode = useUrlParam('plantCode')

  useEffect(() => {
    if (!plantStructure) return

    const newItemId = searchParams.get('entityId') ?? plantCode

    const item = plantStructure?.flatNodes.find((node) => node.data.id === newItemId)
    if (item?.data.id !== selectedItem?.data.id) {
      setSelectedItem(item)
    }
  }, [searchParams, plantCode, plantStructure])

  useEffect(() => {
    if (selectedItem) {
      setSearchParams((params) => {
        params.set('entityId', selectedItem.data.id)
        return params
      })
    }
  }, [selectedItem])

  const arboristTree = useMemo<ReadonlyArray<ArboristNode>>(
    () => (plantStructure ? [mapToArboristNode(plantStructure.tree, shouldShowAddSubdomain)] : []),
    [plantStructure]
  )

  const [filterText, setFilterText] = useState<string>()

  const debouncedSetFilterText = debounce((text: string) => setFilterText(text), 300)

  const searchMatch = useCallback(
    (node: NodeApi<ArboristNode>) => {
      const filters: {hasField: boolean; isMatching: boolean}[] = [
        {
          hasField: !!filterText,
          isMatching: filterText
            ? node.data.name.toLowerCase().includes(filterText.toLowerCase())
            : false
        }
      ]
      return filters.every((filter) => !filter.hasField || filter.isMatching) || node.level === 0
    },
    [filterText]
  )

  const {ref: treeRef, width, height} = useResizeObserver()

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <CardBox p={2}>
        <Stack
          direction="column"
          justifyContent="space-between"
          {...dataTestId('janus-plant-structure-menu')}
          alignItems={'left'}
          gap={2}
        >
          <TextField
            fullWidth
            label={t('janusConfig.plantSetup.freeTextFilter')}
            variant="filled"
            onChange={(e) => debouncedSetFilterText(e.target.value)}
            {...dataTestId('plant-structure-text-filter')}
          />

          <Box ref={treeRef} height={'55vh'}>
            {arboristTree && (
              <Tree
                initialData={arboristTree}
                width={width}
                rowHeight={45}
                height={height}
                indent={10}
                searchTerm={filterText}
                selection={selectedItem?.data.id}
                searchMatch={searchMatch}
                onFocus={(node) => {
                  const item = plantStructure?.flatNodes.find((f) => f.data.id === node.data.id)
                  setSelectedItem(item)
                }}
              >
                {Node}
              </Tree>
            )}
          </Box>
        </Stack>
      </CardBox>
      {itemToLink && (
        <AddSubdomainPopup
          name={upmNodeToLink?.data.name ?? t('janusConfig.plantSetup.unknown')}
          upmId={itemToLink}
          closeAndSubmit={handleAssociateSubdomainsClick}
          close={() => setItemToLink(null)}
          nodesById={nodesByUpmId}
        />
      )}
    </>
  )
}
