import {Button} from '@mui/material'
import {styled} from '@mui/material/styles'

export const QuickFilterOptionButton = styled(Button)(({theme}) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.grey[100],
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: '16px',
  height: '32px',
  color: theme.palette.secondary.main,
  padding: '0.5',
  '&[data-selected="true"]': {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main
  }
}))
