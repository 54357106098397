import {useSettingsQuery} from '../../common/providers/ApiInterface'
import {useUrlParam} from '../../routing'

export const useAssets = () => {
  const plantCode = useUrlParam('plantCode')
  return useSettingsQuery('assetsGeneralInfo', [plantCode], {
    cacheTime: 0,
    staleTime: 0
  })
}
