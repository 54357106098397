import {KilnSvgWrapper} from '@hconnect/common/components/kmsKilnSvgWrapper'
import {appUrl} from '@hconnect/common/hproduce/config'
import {MAINTAIN_HOST} from '@hconnect/common/hproduce/config/maintain'
import {Loader} from '@hconnect/uikit/src/lib2'
import {LoadingButton} from '@mui/lab'
import {Stack, Typography, Alert, Button, Link} from '@mui/material'
import React, {useEffect, useMemo} from 'react'
import {useForm} from 'react-hook-form'

import {ControlledTextField} from '../../../common/components'
import {useUrlParam} from '../../../routing'
import {useCreateKilnConfig} from '../../hooks/assetConfig/useCreateKilnConfig'
import {useGetAssetConfig} from '../../hooks/assetConfig/useGetAssetConfig'
import {useUpdateKilnConfig} from '../../hooks/assetConfig/useUpdateKilnConfig'
import {KilnConfigDto} from '../../types/assetConfig'

export const AssetDetails = ({assetId}: {assetId: string}) => {
  const {data: kilnConfig, isLoading, error} = useGetAssetConfig(assetId)
  const {mutateAsync: createKilnConfig} = useCreateKilnConfig()
  const {mutateAsync: updateKilnConfig} = useUpdateKilnConfig()

  const configNotCreated = !isLoading && (error?.response?.status === 404 || !kilnConfig)

  const plantCode = useUrlParam('plantCode')

  const initialKilnConfig: KilnConfigDto = {
    metadata: {
      configurationType: 'KmsData',
      plantId: plantCode,
      upmType: 'Department',
      typeUpmId: assetId
    },
    payload: {
      layout: {},
      configuration: {
        name: '',
        dimensions: {
          length: '',
          diameter: ''
        },
        isRotationClockwise: true,
        feedSignals: [],
        speedSignals: []
      },
      signalMappings: null
    }
  }

  const defaultValues = useMemo(
    () => ({
      name: kilnConfig?.payload.configuration?.name ?? '',
      kilnLength: kilnConfig?.payload.configuration?.dimensions?.length ?? '',
      kilnDiameter: kilnConfig?.payload.configuration?.dimensions?.diameter ?? '',
      bestDemonstratedProduction: ''
    }),
    [kilnConfig]
  )

  const layoutEmpty = Object.keys(kilnConfig?.payload.layout ?? {}).length === 0

  return (
    <Stack display="flex" flexDirection="column" gap={3} flexGrow={1} maxWidth="1200px">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {kilnConfig && !layoutEmpty && (
            <KilnSvgWrapper
              svgClickCb={() => undefined}
              sensorMappings={[]}
              activeAreas={[]}
              hoveredArea={undefined}
              kilnInfo={{
                id: kilnConfig.metadata.typeUpmId,
                // @ts-expect-error we know layout inner elements
                layout: kilnConfig.payload.layout,
                isRotationClockwise: kilnConfig.payload.layout['isRotationClockwise'],
                isStopped: false
              }}
            />
          )}
          <Alert
            elevation={4}
            severity={layoutEmpty ? 'error' : 'info'}
            sx={{
              borderRadius: '4px'
            }}
            action={
              <Button
                variant="contained"
                color="primary"
                size="small"
                component={Link}
                href={`${appUrl(MAINTAIN_HOST)}/plant/${plantCode}/kms/${assetId}/config`}
              >
                Setup kiln
              </Button>
            }
          >
            <Typography variant="body1">
              {layoutEmpty
                ? 'You need to provide your kiln layout in order to be able to monitor this asset.'
                : 'This asset has a kiln layout configured. You can update it anytime.'}
            </Typography>
          </Alert>
          <AssetDetailsForm
            defaultValues={defaultValues}
            // situation when kilnConfig is not yet created
            kilnConfig={kilnConfig || initialKilnConfig}
            onSubmit={configNotCreated ? createKilnConfig : updateKilnConfig}
          />
        </>
      )}
    </Stack>
  )
}

const AssetDetailsForm = ({
  defaultValues,
  kilnConfig,
  onSubmit
}: {
  defaultValues: {
    name: string
    kilnLength: string | number | null
    kilnDiameter: string | number | null
    bestDemonstratedProduction: string
  }
  kilnConfig: KilnConfigDto
  onSubmit: (data: KilnConfigDto) => Promise<KilnConfigDto>
}) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: {isDirty, isSubmitSuccessful, isSubmitting}
  } = useForm({
    defaultValues,
    shouldFocusError: false
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(defaultValues)
    }
  }, [isSubmitSuccessful, defaultValues, reset])

  const submit = async (data) => {
    if (!isDirty) return
    const newKilnConfig = structuredClone(kilnConfig)
    newKilnConfig.payload.configuration.name = data.name
    newKilnConfig.payload.configuration.dimensions.length = data.kilnLength || null
    newKilnConfig.payload.configuration.dimensions.diameter = data.kilnDiameter || null
    await onSubmit(newKilnConfig)
  }

  return (
    <Stack maxWidth="400px" spacing={2} component="form" onSubmit={handleSubmit(submit)}>
      <ControlledTextField formDataName="name" label="Asset name" control={control} />
      <Stack direction="row" spacing={2}>
        <ControlledTextField
          formDataName="kilnLength"
          label="Kiln length (m)"
          TextFieldProps={{type: 'number'}}
          control={control}
        />
        <ControlledTextField
          formDataName="kilnDiameter"
          label="Kiln diameter (m)"
          TextFieldProps={{type: 'number'}}
          control={control}
        />
      </Stack>
      <LoadingButton
        variant="contained"
        type="submit"
        loading={isSubmitting}
        disabled={isSubmitting || !isDirty}
      >
        <span>Save</span>
      </LoadingButton>
      {/* Not implemented on BE yet */}
      {/* <ControlledTextField
        label="Best demonstrated production"
        formDataName="bestDemonstratedProduction"
        TextFieldProps={{type: 'number'}}
        control={control}
        onSubmit={handleSubmit(submit)}
      /> */}
    </Stack>
  )
}
