import React, {createContext, useContext, useState, ReactNode} from 'react'

interface ItemGroupsContextProps {
  itemGroups: string[] | []
  setItemGroups: (item: string[]) => void
}

const ItemGroupsContext = createContext<ItemGroupsContextProps | undefined>(undefined)

export const ItemGroupsProvider: React.FC<{children: ReactNode}> = ({children}) => {
  const [itemGroups, setItemGroups] = useState<string[]>([])

  return (
    <ItemGroupsContext.Provider value={{itemGroups, setItemGroups}}>
      {children}
    </ItemGroupsContext.Provider>
  )
}

export const useItemGroups = (): ItemGroupsContextProps => {
  const context = useContext(ItemGroupsContext)
  if (!context) {
    throw new Error('useItemGroups must be used within an ItemGroupsContext')
  }
  return context
}
