import type {Permission, PermissionType} from '@hconnect/apiclient'
import React, {useContext, useState, useMemo, useCallback, useEffect} from 'react'

import {usePermissionsQuery} from '../../../permissions'
import {useUrlParam} from '../../../routing'
import {useSelectedItem} from '../SelectedItemProvider'

import {
  plantStructureTabPermissions,
  upmEntityTabsMap,
  PlantStructureTab,
  plantStructureTabs,
  plantStructureTabCustomFilter
} from './tabs/tabs.config'

interface ItemDetailsState {
  selectedTab: PlantStructureTab
  handleTabChange: (tab: PlantStructureTab) => void
  availableTabs: PlantStructureTab[]
}

const ItemDetailsContext = React.createContext<ItemDetailsState>(
  null as unknown as ItemDetailsState
)

export const useItemDetails = () => {
  const context = useContext(ItemDetailsContext)
  if (!context) throw new Error('Cannot use selected item context outside of a context provider')
  return context
}

export const ItemDetailsProvider = ({children}: {children: React.ReactNode}) => {
  const {selectedItem} = useSelectedItem()
  const [selectedTab, setSelectedTab] = useState<PlantStructureTab>('general')
  const plantCode = useUrlParam('plantCode')
  const {data} = usePermissionsQuery()

  const handleTabChange = useCallback((tab: PlantStructureTab) => {
    setSelectedTab(tab)
  }, [])

  const permissionFilteredTabs: PlantStructureTab[] = useMemo(() => {
    return plantStructureTabs.filter((tab) => {
      const permissionsWhitelist = plantStructureTabPermissions[tab]
      if (permissionsWhitelist) {
        return permissionsWhitelist.some((permission) =>
          hasPermission(data || [], permission as PermissionType, plantCode)
        )
      }
      return true
    })
  }, [data, plantCode])

  const availableTabs: PlantStructureTab[] = useMemo(() => {
    if (!selectedItem) return []

    return upmEntityTabsMap[selectedItem.type]
      .filter((tab: PlantStructureTab) => permissionFilteredTabs.includes(tab))
      .filter((tab) => plantStructureTabCustomFilter[tab]({selectedItem}))
  }, [permissionFilteredTabs, selectedItem])

  useEffect(() => {
    if (!availableTabs.includes(selectedTab)) {
      setSelectedTab(availableTabs[0])
    }
  }, [availableTabs, selectedTab])

  if (!selectedItem) {
    return null
  }

  return (
    <ItemDetailsContext.Provider value={{selectedTab, handleTabChange, availableTabs}}>
      {children}
    </ItemDetailsContext.Provider>
  )
}

function hasPermission(
  permissions: Permission[],
  permission: PermissionType,
  plantId: string | undefined = undefined
) {
  if (!plantId) {
    return !!permissions.find(({permissionType}) => permissionType === permission)
  } else {
    const foundPermissions = permissions.filter(({permissionType}) => permissionType === permission)
    if (foundPermissions.length) {
      const plantIds = foundPermissions.map((perm) => JSON.parse(perm.dataScope).plantId)
      return plantIds.includes(plantId)
    } else {
      return false
    }
  }
}
