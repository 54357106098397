import {HPCommentItem} from '@hconnect/uikit/src/lib2/components/comment'
import {Typography, Box} from '@mui/material'
import moment from 'moment'

import {Comment} from '../../types'

export const ListComment = ({
  comment,
  onClick
}: {
  comment: Comment
  onClick: (id: string) => void
}) => {
  const {userName: user, kpiId, value, kpiName, commentDate, id} = comment

  return (
    <Box sx={{cursor: 'pointer'}} onClick={() => onClick(kpiId)}>
      <HPCommentItem
        userName={user}
        commentDate={moment.utc(commentDate).local()}
        title={kpiName}
        content={<Typography variant="body1">{value}</Typography>}
        data-test-id={`kpi-comment-${id}`}
      />
    </Box>
  )
}
