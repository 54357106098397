import {MaterialSource, MaterialType} from '@hconnect/common/types'

import {AutocompleteOption, SelectedMaterial} from '../../../types'

export type MaterialFormDefaultValues = {
  type: MaterialType | null
  name: string
  globalMaterial: AutocompleteOption | null
  source: MaterialSource
}

export const getMaterialFormDefaultValues = (
  material: SelectedMaterial,
  globalMaterialOptions: AutocompleteOption[]
) => {
  return {
    type: material.type ?? null,
    name: material.name,
    source: material.source,
    globalMaterial: material.globalMaterialId
      ? globalMaterialOptions.find(
          (globalMaterialOption) => Number(globalMaterialOption.id) === material.globalMaterialId
        ) ?? null
      : null
  }
}
