import {BackToLink, useBreakPoints} from '@hconnect/uikit/src/lib2'
import {Box, Typography, Stack} from '@mui/material'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {Status, CalendarRange} from '../../enums'
import {useCalendar} from '../../hooks/useCalendar'
import {useCanApproveData} from '../../hooks/useCanApproveData'
import {CalendarInfo} from '../../types'
import {CalendarDatePicker} from '../common/CalendarDatePicker'
import {ProgressBar} from '../common/ProgressBar'

import {DailyCalendar} from './DailyCalendar'

export const DateSelection = ({
  selectedDate,
  setSelectedDate,
  range,
  isSingleDayView,
  title,
  onBackButtonClick,
  setRangeCanBeApproved
}: {
  selectedDate: string | undefined
  setSelectedDate: (date: string | undefined) => void
  range: CalendarRange
  isSingleDayView?: boolean
  title: string
  onBackButtonClick?: () => void
  setRangeCanBeApproved?: (approve: boolean) => void
}) => {
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const isTablet = ['md'].includes(screenSizes)
  const smallScreen = isMobile || isTablet

  const [calendarInfo, setCalendarInfo] = useState<CalendarInfo | undefined>()
  const canEditData = useCanApproveData(!!isSingleDayView, selectedDate)

  const {data, isLoading, isFetching} = useCalendar(range, selectedDate)

  useEffect(() => {
    if (calendarInfo && !!setRangeCanBeApproved) {
      if (!canEditData) {
        setRangeCanBeApproved(false)
      } else {
        if (!isSingleDayView) {
          setRangeCanBeApproved(calendarInfo?.days.every((day) => day.status === Status.Saved))
        } else {
          const selected = moment(selectedDate)
          const calendarDay = calendarInfo.days.find((day) =>
            moment(day.date).isSame(selected, 'day')
          )
          if (calendarDay) {
            setRangeCanBeApproved(calendarDay.status === Status.Saved)
          }
        }
      }
    }
  }, [calendarInfo, setRangeCanBeApproved, range, isSingleDayView, selectedDate, canEditData])

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setCalendarInfo(data)
    }
  }, [data, isLoading, isFetching])

  const daysNumber = calendarInfo?.days.length ?? 0
  const completedDays =
    calendarInfo?.days.filter(
      (d) =>
        d.status === Status.PartiallySaved ||
        d.status === Status.Saved ||
        d.status === Status.Approved
    )?.length ?? 0
  const progress = (completedDays * 100) / daysNumber
  const {t} = useTranslation()

  return (
    <Stack width={isMobile ? '100%' : '95%'}>
      {isSingleDayView && onBackButtonClick && (
        <BackToLink
          navigate={onBackButtonClick}
          targetName={t('janusConfig.dailyEntry.backToDaily')}
          target={t('janusConfig.dailyEntry.backToDaily')}
        />
      )}
      <Stack gap={2}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          {!smallScreen && (
            <Typography variant="h1" sx={{width: '60%'}}>
              {title}
            </Typography>
          )}
          <Box
            sx={{width: smallScreen ? '100%' : '40%'}}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap={2}
            alignItems="flex-end"
          >
            {!smallScreen && calendarInfo && (
              <ProgressBar
                backgroundColor="#01356A"
                progress={progress}
                primaryText={t('janusConfig.dailyEntry.progress')}
                secondaryText={t('janusConfig.dailyEntry.daysProgress', {
                  completed: completedDays,
                  all: daysNumber
                })}
                testId="daily-entries-progress"
                height={24}
                width="60%"
                textColor="rgba(255,255,255,0.6)"
              />
            )}
            <CalendarDatePicker
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              fullWidth={isMobile}
              type={range}
            />
          </Box>
        </Box>
        {calendarInfo && isSingleDayView && (
          <DailyCalendar
            calendarInfo={calendarInfo}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        )}
      </Stack>
    </Stack>
  )
}
