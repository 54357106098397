import {Stack} from '@mui/material'
import * as React from 'react'
import './kilnPlaceholder.css'
import {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {KilnInfo, SignalAnomaly, SignalMapping} from '../kmsStatus'

import {DrivePart, KilnShellPart, MaterialFlow, Rotation, StationPart} from './KilnModel'

interface KilnSvgWrapperProps {
  svgClickCb: (localNames: string[]) => void
  sensorMappings: SignalMapping[]
  anomalies?: SignalAnomaly[]
  activeAreas: string[]
  hoveredArea: string | undefined
  speedValue?: number
  isWidget?: boolean
  kilnInfo: KilnInfo
}

export const KilnSvgWrapper = ({
  svgClickCb,
  sensorMappings,
  anomalies,
  activeAreas,
  hoveredArea,
  speedValue,
  isWidget,
  kilnInfo
}: KilnSvgWrapperProps) => {
  const {t} = useTranslation()

  const svgRef = useRef<null | SVGElement>(null)
  const warningIds = anomalies?.filter((a) => a.status === 'Warning').map((a) => a.svgId) ?? []
  const errorIds = anomalies?.filter((a) => a.status === 'Alarm').map((a) => a.svgId) ?? []
  const [prevActiveAreas, setPrevActiveAreas] = useState<string[]>([])
  const [prevWarnings, setPrevWarnings] = useState<string>('[]')
  const [prevErrors, setPrevErrors] = useState<string>('[]')

  const handleSvgClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    const target = e.nativeEvent.target as SVGElement
    const parent = target.parentElement as HTMLElement
    const clickedElement = target.id ? target : parent
    if (!clickedElement.id) return

    const sensors = sensorMappings.find((s) => s.svgId === clickedElement.id)
    if (!sensors) return

    e.stopPropagation()
    svgClickCb(sensors.localNames)
  }

  const removeOverlays = (styleClass: string) => {
    const collection = document.getElementsByClassName(styleClass)
    Array.from(collection).forEach((element) => element.classList.remove(styleClass))
  }

  const addOverlay = (parent: SVGElement, elementIds: string[], styleClass: string) => {
    removeOverlays(styleClass)
    elementIds.map((elementId) => {
      const target = parent.querySelector('#' + elementId)
      if (target) {
        target.classList.add(styleClass)
      }
    })
  }

  // Automatically add hover class to kiln areas with sensors connected
  useEffect(() => {
    const activeAreas: string[] = []
    sensorMappings.forEach((mapping) => {
      if (!activeAreas.includes(mapping.svgId)) {
        activeAreas.push(mapping.svgId)
      }
    })

    if (svgRef?.current) {
      const prevActive = svgRef.current?.getElementsByClassName('kiln-part')
      Array.from(prevActive).forEach((element) => element.classList.remove('kiln-part'))
    }

    activeAreas.map((area) => {
      if (svgRef?.current) {
        const parent = svgRef.current
        const target = parent.querySelector('#' + area)
        if (target) {
          target.classList.add('kiln-part')
        } else {
          console.warn('SVG area with id missing : ' + area)
        }
      }
    })
  }, [sensorMappings, svgRef])

  // Based on received warnings and errors display corresponding overlay over kiln area with problematic sensors
  useEffect(() => {
    const errorsStr = JSON.stringify(errorIds)
    const warningsStr = JSON.stringify(warningIds)
    if (svgRef?.current && (errorsStr !== prevErrors || warningsStr !== prevWarnings)) {
      setPrevWarnings(warningsStr)
      setPrevErrors(errorsStr)
      if (errorIds) addOverlay(svgRef.current, errorIds, 'error')
      if (warningIds) addOverlay(svgRef.current, warningIds, 'warning')
    }
  }, [svgRef, warningIds, errorIds])

  useEffect(() => {
    if (svgRef?.current) {
      const parent = svgRef.current
      activeAreas.map((areaId) => {
        const target = parent.querySelector('#' + areaId)
        if (target) target.classList.add('kiln-part-active')
      })
      prevActiveAreas.map((prevAreaId) => {
        const stillActive = activeAreas.findIndex((areaId) => areaId === prevAreaId)
        if (stillActive === -1) {
          const target = parent.querySelector('#' + prevAreaId)
          if (target) target.classList.remove('kiln-part-active')
        }
      })
    }

    setPrevActiveAreas(activeAreas)
  }, [svgRef, activeAreas, prevActiveAreas])

  useEffect(() => {
    if (svgRef?.current) {
      const parent = svgRef.current

      const target = parent.querySelector('#' + hoveredArea)
      const activeHoverAreas = Array.from(parent?.getElementsByClassName('kiln-part-hover'))
      activeHoverAreas.map((area) => {
        area.classList.remove('kiln-part-hover')
      })
      if (target) target.classList.add('kiln-part-hover')
    }
  }, [svgRef, hoveredArea])

  return (
    <Stack alignItems="flex-start" sx={{maxHeight: '100%'}}>
      <svg
        ref={svgRef as React.LegacyRef<SVGSVGElement> | undefined}
        viewBox={`0 0 ${kilnInfo.layout.referenceDimensions.width + 140} ${
          kilnInfo.layout.referenceDimensions.height
        }`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        height="100%"
        // width="100%" // this will center the svg
        onClick={handleSvgClick}
        style={{
          maxHeight: '300px'
        }}
      >
        <Rotation
          x={0}
          y={kilnInfo.layout.drive.position === 'bottom' ? -25 : 0}
          duration={speedValue ? Math.round(60 / speedValue) : 0}
          isRotationClockwise={kilnInfo.layout.isRotationClockwise}
        />
        <svg x={140}>
          <KilnShellPart {...kilnInfo.layout.kilnShell} />

          {kilnInfo.layout.stations.map((station) => (
            <StationPart key={station.id} {...station} />
          ))}
          <DrivePart
            {...kilnInfo.layout.drive}
            flip={kilnInfo.layout.materialFlow !== 'rightToLeft'}
          />
          <MaterialFlow
            materialFlow={kilnInfo.layout.materialFlow}
            x={550}
            y={kilnInfo.layout.kilnShell.y + kilnInfo.layout.kilnShell.height / 2 - 25}
            text={t('kilnConfig.materialFlow')}
            display={isWidget ? 'none' : 'block'}
          />
        </svg>
      </svg>
    </Stack>
  )
}
