import {dataTestId} from '@hconnect/uikit'
import {
  DashboardCustomizeOutlined as DashboardCustomizeIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material'
import {Box, Button, IconButton, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

export const ResourceCardHeader = ({
  isMobile,
  title,
  open,
  handleToggle
}: {
  isMobile?: boolean
  title: string
  open: boolean
  handleToggle: () => void
}) => {
  const {t} = useTranslation()

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={{
        cursor: 'pointer'
      }}
      onClick={handleToggle}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
        {...dataTestId(`resource-details-title-${title}`)}
      >
        <IconButton>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
        <Typography variant="h4">{title}</Typography>
      </Box>
      {!isMobile ? (
        <Button
          onClick={(e) => e.stopPropagation()}
          variant="text"
          startIcon={<DashboardCustomizeIcon />}
        >
          {t('janusConfig.monthlyEntry.customize')}
        </Button>
      ) : (
        <IconButton onClick={(e) => e.stopPropagation()}>
          <DashboardCustomizeIcon color="primary" />
        </IconButton>
      )}
    </Box>
  )
}
