import {FeatureDefinition, FeatureFlag} from '@hconnect/common/types'
import {isFeatureSupported} from '@hconnect/common/utils'
import {useMemo} from 'react'

import {useUrlParam} from '../../routing'

type FeatureFlagKey = 'kpiCalculationsTab'

const whitelistedKpiCalculationsTabPlants = [
  'NO11',
  'PL1A',
  'U175',
  'F010',
  '0014',
  'U160',
  'TG62',
  'C203',
  'C205',
  'C102',
  'EG11',
  'EG13',
  'TZ10',
  'U100',
  'U007',
  'U145',
  'NL11',
  'EG10',
  'F001',
  'F002',
  'F003',
  'F004',
  'F005',
  'F007',
  'F008',
  'F009',
  'U137',
  'U157',
  'U159',
  '7010',
  '0016',
  '0011',
  'GB1K',
  'GB1P',
  '7001',
  '7004',
  '7006'
]

const stageSpecific: Record<string, FeatureFlag<FeatureFlagKey>> = {
  qa: {
    kpiCalculationsTab: {
      whitelist: whitelistedKpiCalculationsTabPlants,
      blacklist: []
    }
  },
  prod: {
    kpiCalculationsTab: {
      whitelist: whitelistedKpiCalculationsTabPlants,
      blacklist: []
    }
  }
}

export const useFeatureFlag = (flag: FeatureFlagKey) => {
  const plantCode = useUrlParam('plantCode')
  return useMemo(() => {
    const stage = process.env.REACT_APP_STAGE
    const stageSpecificFlags = (stage && stageSpecific[stage]) || {}

    const feature: FeatureDefinition | undefined = stageSpecificFlags[flag]

    return isFeatureSupported(feature, plantCode)
  }, [plantCode, flag])
}
