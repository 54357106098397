import {MaterialSource} from '@hconnect/common/types'
import type {OperationModeResponse} from '@hconnect/common/types'
import {compact} from 'lodash'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {InfoGroup, InfoGroupProps} from '../../../common/components'
import type {ScheduleItem} from '../../../common/types'
import type {MaterialWithRecipes} from '../../types'

interface MaterialChangeSourceInfoProps {
  scheduleItems: ScheduleItem[]
  material: MaterialWithRecipes
  operationModes: OperationModeResponse[]
}

function cutStringTo40Chars(str: string) {
  return str.length <= 40 ? str : `${str.substring(0, 37)}...`
}

export const MaterialChangeSourceInfo: React.FC<MaterialChangeSourceInfoProps> = ({
  material,
  scheduleItems,
  operationModes
}) => {
  const {t} = useTranslation()
  const productsStr = cutStringTo40Chars(material.products.map(({name}) => name).join(', '))
  const recipesStr = cutStringTo40Chars(material.recipes.map(({name}) => name).join(', '))
  const operationModesStr = cutStringTo40Chars(operationModes.map(({name}) => name).join(', '))

  const panels: InfoGroupProps['panels'] = compact([
    material.products.length > 0 && {
      accentColor: 'warning',
      content: (
        <Trans
          i18nKey="materialsSettings.deleteMaterialAssignedProducts"
          values={{products: productsStr}}
        />
      )
    },
    material.recipes.length > 0 && {
      accentColor: 'warning',
      content: (
        <Trans
          i18nKey="materialsSettings.deleteMaterialAssignedRecipes"
          values={{recipe_count: material.recipes.length, recipes: recipesStr}}
        />
      )
    },
    operationModes.length > 0 && {
      accentColor: 'warning',
      content: (
        <Trans
          i18nKey="materialsSettings.deleteMaterialAssignedOperationModes"
          values={{
            operation_mode_count: operationModes.length,
            operation_modes: operationModesStr
          }}
        />
      )
    },
    scheduleItems.length > 0 && {
      accentColor: 'warning',
      content: (
        <Trans
          i18nKey="assetsSettings.deleteOperationModesAssignedScheduleItems"
          values={{
            number: scheduleItems.length
          }}
        />
      )
    }
  ])

  return (
    <InfoGroup
      description={
        <Trans
          i18nKey="materialsSettings.changeMaterialSourceConfirmation"
          values={{
            source:
              material.source === MaterialSource.BoughtFromVendor
                ? t('materialsSettings.producedInPlant')
                : t('materialsSettings.receivedFromVendor')
          }}
        />
      }
      panels={panels}
    />
  )
}
