import {PermissionType} from '@hconnect/apiclient'

import {StructureNodeUnistWithAncestors, UpmEntityType} from '../../../../common/types'

export const plantStructureTabs = [
  'layout',
  'sensors',
  'kpis',
  'operationModes',
  'standardTimes',
  'general'
] as const

export type PlantStructureTab = (typeof plantStructureTabs)[number]

export const commonTabs: PlantStructureTab[] = [
  'sensors',
  'kpis',
  'operationModes',
  'standardTimes'
]

export const upmEntityTabsMap: Record<UpmEntityType, PlantStructureTab[]> = {
  Plant: ['general', ...commonTabs],
  Area: commonTabs,
  ProductionLine: commonTabs,
  Department: ['layout', ...commonTabs],
  Equipment: ['general', ...commonTabs]
}

export const plantStructureTabPermissions: Record<PlantStructureTab, PermissionType[] | null> = {
  general: null,
  layout: ['CREATE_MASTER_DATA'],
  sensors: ['VIEW_UPM'],
  kpis: null,
  operationModes: null,
  standardTimes: null
}

export const plantStructureTabCustomFilter: Record<
  PlantStructureTab,
  ({selectedItem}: {selectedItem: StructureNodeUnistWithAncestors}) => boolean
> = {
  general: () => true,
  layout: ({selectedItem}) => selectedItem.data.name === 'Kiln',
  sensors: () => true,
  kpis: () => true,
  operationModes: () => true,
  standardTimes: () => true
}
