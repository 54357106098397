import {dataTestId} from '@hconnect/uikit'
import {BackToLink, useBreakPoints} from '@hconnect/uikit/src/lib2'
import {Box, Typography, Stack} from '@mui/material'
import moment from 'moment'
import React, {useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSearchParams} from 'react-router-dom'

import {MonthsFilterOptions} from '../../enums'
import {Group} from '../../types'

import {ItemGroupsProvider, useItemGroups} from './ItemGroupsContext'
import {MonthlyEntryView} from './MonthlyEntryView'
import {MonthlyHeader} from './MonthlyHeader'

export const MonthlyEntry = () => {
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const [searchParams, setSearchParams] = useSearchParams()

  const [selectedcontextMonthsFilterOptions, setSelectedContextMonthsFilterOptions] =
    useState<MonthsFilterOptions>(MonthsFilterOptions.Month)

  const [selectedResource, setSelectedResource] = useState<Group>()

  const selectedDate = useMemo(() => {
    const date = searchParams.get('date')
    const defaultDate = moment().subtract(1, 'days').toISOString()
    if (!date) {
      setSearchParams((params) => {
        params.set('date', defaultDate)
        return params
      })
    }
    return date ?? defaultDate
  }, [searchParams, setSearchParams])

  const {t} = useTranslation()

  const showTreeContainer = !isMobile || !selectedResource
  const showResourceDetails = selectedResource && selectedcontextMonthsFilterOptions

  return (
    <ItemGroupsProvider>
      <Stack gap={2} alignItems="center" width="100%">
        <Box
          width={isMobile ? '100%' : '95%'}
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          justifyContent="space-between"
          gap={2}
          {...dataTestId('monthly-entry-container')}
        >
          <Stack sx={{width: isMobile ? '100%' : '30%'}}>
            {selectedResource && isMobile && (
              <BackToLink
                navigate={() => setSelectedResource(undefined)}
                targetName={t('janusConfig.monthlyEntry.backToGlobalPlantMenu')}
                target={t('backToGlobalPlantMenu')}
              />
            )}
            <Typography variant="h1">
              {isMobile && selectedResource
                ? selectedResource?.name
                : t('janusConfig.monthlyEntry.monthlyEntry')}
            </Typography>
          </Stack>
          <MonthlyHeader
            selectedDate={selectedDate}
            selectedcontextMonthsFilterOptions={selectedcontextMonthsFilterOptions}
            setSelectedContextMonthsFilterOptions={(option) =>
              setSelectedContextMonthsFilterOptions(option)
            }
          />
        </Box>
        <MonthlyEntryView
          periodType={selectedcontextMonthsFilterOptions}
          selectedDate={selectedDate}
          selectedResource={selectedResource}
          showDetails={!!showResourceDetails}
          showTreeContainer={showTreeContainer}
          setSelectedResource={setSelectedResource}
          isMobile={isMobile}
        />
      </Stack>
    </ItemGroupsProvider>
  )
}
