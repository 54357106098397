import {useSettingsQuery} from '../../common/providers/ApiInterface'
import {useUrlParam} from '../../routing'
import {MonthsFilterOptions} from '../enums'

export const useResourceDetails = (
  groupId: string,
  periodType: MonthsFilterOptions,
  date?: string
) => {
  const plantCode = useUrlParam('plantCode')
  return useSettingsQuery('resourceDetails', [plantCode, groupId, periodType, date], {
    enabled: !!date,
    cacheTime: 0,
    staleTime: 0
  })
}
