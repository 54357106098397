import {dataTestId} from '@hconnect/uikit'
import {Table, TableContainer, SortDirection} from '@mui/material'
import {orderBy} from 'lodash'
import React, {useState} from 'react'

import {
  LimsSamplingPoint,
  AutocompleteOption,
  SamplingPointTypesAutocompleteOption
} from '../../types'

import {SamplingPointsSettingsTableBody} from './SamplingPointsSettingsTableBody/SamplingPointsSettingsTableBody'
import {SamplingPointsSettingsTableHeader} from './SamplingPointsSettingsTableHeader/SamplingPointsSettingsTableHeader'

export interface SamplingPointsSettingsTableProps {
  limsSamplingPoints: LimsSamplingPoint[]
  samplingPointTypeOptions: SamplingPointTypesAutocompleteOption[]
  assetOptions: AutocompleteOption[]
  storageOptions: AutocompleteOption[]
  plantCode: string
}

export const SamplingPointsSettingsTable: React.FC<SamplingPointsSettingsTableProps> = ({
  limsSamplingPoints,
  samplingPointTypeOptions,
  assetOptions,
  storageOptions,
  plantCode
}) => {
  const [orderDirection, setOrderDirection] = useState<SortDirection>('asc')
  return (
    <TableContainer {...dataTestId('sampling_points_table')}>
      <Table size="small">
        <SamplingPointsSettingsTableHeader
          orderDirection={orderDirection}
          setOrderDirection={setOrderDirection}
        />
        <SamplingPointsSettingsTableBody
          limsSamplingPoints={orderBy(limsSamplingPoints, ['code'], orderDirection)}
          samplingPointTypeOptions={samplingPointTypeOptions}
          assetOptions={assetOptions}
          storageOptions={storageOptions}
          plantCode={plantCode}
        />
      </Table>
    </TableContainer>
  )
}
