import {useState} from 'react'

import {EditSignal} from '../../../../upm/components/SignalForm/EditSignal'
import {NewSignal} from '../../../../upm/components/SignalForm/NewSignal'
import {SignalList} from '../../../../upm/components/SignalList/SignalList'
import {ItemDetailsLayout} from '../ItemDetailsLayout'

type SensorView = {
  type: SensorViewType
  signalId: string | null
}

type SensorViewType = 'list' | 'new' | 'edit'

export const Sensors = () => {
  const [view, setView] = useState<SensorView>({
    type: 'list',
    signalId: null
  })

  return (
    <>
      {view.type === 'list' && (
        <ItemDetailsLayout>
          <SignalList
            onAddClick={() => setView({type: 'new', signalId: null})}
            onDetailClick={(signalId) => setView({type: 'edit', signalId})}
          />
        </ItemDetailsLayout>
      )}
      {view.type === 'new' && (
        <NewSignal
          onCreated={(signalId) => setView({type: 'edit', signalId})}
          onGoBack={() => setView({type: 'list', signalId: null})}
        />
      )}
      {view.type === 'edit' && (
        <EditSignal
          signalId={view.signalId}
          onGoBack={() => setView({type: 'list', signalId: null})}
        />
      )}
    </>
  )
}
