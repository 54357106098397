import {dataTestId, useNotification, useTranslation} from '@hconnect/uikit'
import {CardBox, Loader, useBreakPoints} from '@hconnect/uikit/src/lib2'
import {
  ChatBubbleOutline as ChatBubbleOutlineIcon,
  Check as CheckIcon,
  ErrorOutlined as ErrorOutlinedIcon
} from '@mui/icons-material'
import {
  Box,
  Grid,
  Stack,
  Typography,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TableContainer,
  TextField,
  styled
} from '@mui/material'
import moment from 'moment'
import React, {useEffect, useMemo, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useSearchParams} from 'react-router-dom'

import {invalidateSettingsQuery} from '../../../common/providers'
import {usePermission} from '../../../permissions'
import {useUrlParam} from '../../../routing'
import {CalendarRange, EditKpiStatus, SaveButtonState, Status, UnitType} from '../../enums'
import {useApproveCumulatedKpi} from '../../hooks/useApproveCumulatedKpi'
import {useCumulatedKpis} from '../../hooks/useCumulatedKpis'
import {useEditKpiFinalValue} from '../../hooks/useEditKpiFinalValue'
import {EditedFinalKpiValue, FinalValue} from '../../types'

import {DailyEntryHeader} from './DailyEntryHeader'
import {DateSelection} from './DateSeletion'
import {WarningBlock} from './WarningBlock'

const StickyTableRow = styled(TableRow)({
  position: 'sticky',
  bottom: 60,
  backgroundColor: '#fff',
  zIndex: 1200,
  minHeight: '60px'
})
const LastStickyTableRow = styled(StickyTableRow)({
  bottom: 0
})

const StickyTableCell = styled(TableCell)({
  '&.MuiTableCell-head': {
    position: 'sticky',
    bottom: 0
  },
  '&.MuiTableCell-body': {
    position: 'sticky',
    bottom: 0
  },
  borderBottom: 'none'
})
const LastRowStickyTableCell = styled(StickyTableCell)({
  borderBottom: 'none'
})

const StyledTextField = styled(TextField)({
  height: '40px',

  '& .MuiInputBase-root': {
    height: '100%'
  },

  '& .MuiInputBase-input': {
    height: '100%'
  },

  '& .MuiInputLabel-root': {
    top: '-10px'
  }
})

const TableCellBorder = styled('div')({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  height: '1px',
  backgroundColor: 'rgba(232, 236, 240, 1)'
})

const useSmallScreen = () => {
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const isTablet = ['md'].includes(screenSizes)
  const smallScreen = isMobile || isTablet

  return {isMobile, isTablet, smallScreen}
}

export const MonthlyView = ({selectSingleDay}: {selectSingleDay: () => void}) => {
  const {smallScreen} = useSmallScreen()

  const {t} = useTranslation()

  const [searchParams, setSearchParams] = useSearchParams()

  const selectedDate = useMemo(() => {
    const date = searchParams.get('date')
    const defaultDate = moment().subtract(1, 'days').toISOString()
    if (!date) {
      setSearchParams((params) => {
        params.set('date', defaultDate)
        return params
      })
    }
    return date ?? defaultDate
  }, [searchParams, setSearchParams])

  const {data: entries, isLoading, isFetching} = useCumulatedKpis(selectedDate)

  const methods = useForm<{entries: FinalValue[]}>({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: {
      entries: []
    }
  })

  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: {isDirty, isValid, errors}
  } = methods

  const formattedDate = useMemo(
    () => moment(selectedDate).format('MMMM YYYY').toString(),
    [selectedDate]
  )

  const {mutate: editFinalValues} = useEditKpiFinalValue()

  const canEditData = usePermission('EDIT_JANUS_PLANT_DATA')
  const [rangeCanBeApproved, setRangeCanBeApproved] = useState<boolean>(false)
  const enableSaveButton = canEditData && isDirty && isValid

  const plantCode = useUrlParam('plantCode')

  const {notify} = useNotification()

  const {mutate: approveCumulatedKpi} = useApproveCumulatedKpi()

  const approve = () => {
    approveCumulatedKpi(
      {plantCode, date: selectedDate},
      {
        onSuccess: (code: number) => {
          if (code !== 1) {
            notify('error', t('error.failedToApprove'))
          } else {
            invalidateSettingsQuery('cumulatedKpis', plantCode, selectedDate)
            invalidateSettingsQuery('calendarInfo', plantCode, range, selectedDate)
            notify('success', t('success.editReport'))
          }
        },
        onError: () => {
          notify('error', t('error.reportUpdateError'))
        }
      }
    )
  }

  const submit = handleSubmit((data) => {
    if (!data || !isDirty || !data.entries) return

    const changedEntries = data.entries.filter((final) => final.unitTypeId === UnitType.Numeric)

    if (changedEntries) {
      const newEntries = changedEntries.map((entry) => ({
        ...entry,
        value: entry.value.toString()
      }))

      editFinalValues(
        {plantCode, value: newEntries, date: selectedDate},
        {
          onError: () => {
            reset()
            notify('error', t('error.reportUpdateError'))
          },
          onSuccess: (response: EditedFinalKpiValue[]) => {
            if (
              response.some(
                (kpi) =>
                  kpi.status !== EditKpiStatus.Success && kpi.status !== EditKpiStatus.NotUpdated
              )
            ) {
              const errors = response.filter(
                (kpi) =>
                  kpi.status !== EditKpiStatus.Success && kpi.status !== EditKpiStatus.NotUpdated
              )
              if (errors) {
                errors.forEach((error) => {
                  const index = data.entries.findIndex(
                    (entry) => entry.specificKpiId === error.specificKpiId
                  )

                  setError(`entries.${index}.value`, {
                    type: 'manual',
                    message:
                      error.status === EditKpiStatus.VersionConcurrencyFail
                        ? t('error.someoneAlreadyMadeChanges')
                        : t('error.validationFailed')
                  })
                })
              }
              notify('error', t('error.reportUpdateError'))
            }
            invalidateSettingsQuery('cumulatedKpis', plantCode, selectedDate)
          }
        }
      )
    }
  })

  useEffect(() => {
    const newEntries = entries?.finalValues.map((entry, index) => ({
      ...entry,
      index
    }))
    reset({entries: newEntries})
  }, [entries, reset])

  const range = useMemo(
    () => (smallScreen ? CalendarRange.Weekly : CalendarRange.Monthly),
    [smallScreen]
  )
  const isLoadingOrFetching = isLoading || isFetching
  const noData = useMemo(() => !entries?.kpis[0]?.values, [entries])

  const containerHeight = useMemo(() => {
    const warnings = entries?.warnings.length ?? 0
    switch (warnings) {
      case 1:
        return 680
      case 2:
        return 610
      case 3:
        return 540
      default:
        return 820
    }
  }, [entries])

  return (
    <Stack gap={3} alignItems="center">
      <DateSelection
        setSelectedDate={(date) => {
          setSearchParams((params) => {
            params.set('date', date || '')
            return params
          })
        }}
        selectedDate={selectedDate}
        range={range}
        title={t('janusConfig.dailyEntry.dailyEntry')}
        setRangeCanBeApproved={setRangeCanBeApproved}
      />
      <Grid container spacing={2} {...dataTestId('daily-entry-container')}>
        <Grid item xs={12}>
          <CardBox p={2} minHeight="160px" sx={{overflowY: 'auto'}}>
            <Stack gap={3}>
              <DailyEntryHeader
                selectedDate={formattedDate}
                saveButtonState={
                  enableSaveButton ? SaveButtonState.Changed : SaveButtonState.Disabled
                }
                onSaveClick={() => void submit()}
                onApproveClick={approve}
                canRangeBeApproved={rangeCanBeApproved}
              />
              {entries?.warnings.map((warning, index) => (
                <WarningBlock key={`warning-block-${index}-${warning}`} messageType={warning} />
              ))}
              {isLoadingOrFetching ? (
                <Loader />
              ) : noData ? (
                <Box width="100%" display="flex" flexDirection="row" justifyContent="center">
                  <Typography>{t('janusConfig.dailyEntry.noData')}</Typography>
                </Box>
              ) : (
                <Stack>
                  <TableContainer sx={{maxHeight: containerHeight, minHeight: 540}}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell key="date-header">{t('janusConfig.dailyEntry.day')}</TableCell>
                          {entries?.kpis[0]?.values?.map((kpi) => (
                            <TableCell
                              sx={{textAlign: 'right'}}
                              key={`${kpi.specificKpiId}-${kpi.id}-${kpi.value}`}
                            >
                              {kpi.name}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {entries?.kpis?.map((report) => (
                          <TableRow
                            key={`${report.id}-${report.date}-${report.status}`}
                            sx={{cursor: 'pointer'}}
                          >
                            <TableCell
                              onClick={() => {
                                setSearchParams((params) => {
                                  params.set('date', report.date)
                                  return params
                                })
                                selectSingleDay()
                              }}
                            >
                              <Typography variant="caption">
                                <Box display="flex" flexDirection="row" gap={1} alignItems="center">
                                  {report.status === Status.Approved ||
                                  report.status === Status.Saved ? (
                                    <CheckIcon color="success" sx={{width: '16px'}} />
                                  ) : (
                                    <ErrorOutlinedIcon color="error" sx={{width: '16px'}} />
                                  )}
                                  {moment(report.date).format('DD.MM.YYYY').toString()}
                                </Box>
                              </Typography>
                            </TableCell>
                            {report.values.map((kpi) => (
                              <TableCell
                                key={`${kpi.id}-${kpi.specificKpiId}-${kpi.value}-${kpi.modificationDate}`}
                                onClick={() =>
                                  setSearchParams((params) => {
                                    params.set('kpiId', kpi.id)
                                    params.set('date', report.date)
                                    return params
                                  })
                                }
                              >
                                <Typography variant="caption">
                                  <Box
                                    display="flex"
                                    justifyContent="right"
                                    flexDirection="row"
                                    gap={1}
                                  >
                                    <Box sx={{width: '16px'}}>
                                      {kpi.commentCount > 0 && (
                                        <ChatBubbleOutlineIcon sx={{fontSize: '16px'}} />
                                      )}
                                    </Box>
                                    <Typography variant="caption">{kpi.value ?? '-'}</Typography>
                                  </Box>
                                </Typography>
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                        <StickyTableRow
                          sx={{
                            boxShadow: '0 -4px 6px rgba(0, 0, 0, 0.04)'
                          }}
                        >
                          <StickyTableCell key={'sum-cell-title'}>
                            <Stack width="100%">
                              <Typography variant="body2">
                                {t('janusConfig.dailyEntry.monthlySum')}
                              </Typography>
                              <Typography variant="caption">
                                {t('janusConfig.dailyEntry.adjustment')}
                              </Typography>
                            </Stack>
                            <TableCellBorder />
                          </StickyTableCell>
                          {entries?.monthlySums.map((sum) => (
                            <StickyTableCell
                              key={`sum-cell-${sum.specificKpiId}`}
                              sx={{textAlign: 'right'}}
                            >
                              <Stack width="100%">
                                <Typography
                                  {...dataTestId(`sum-cell-${sum.specificKpiId}`)}
                                  fontWeight="bold"
                                  variant="caption"
                                >
                                  {sum.value}
                                </Typography>
                                <Typography
                                  {...dataTestId(`adjustment-cell-${sum.specificKpiId}`)}
                                  variant="caption"
                                >
                                  {sum.adjustment}
                                </Typography>
                              </Stack>
                              <TableCellBorder />
                            </StickyTableCell>
                          ))}
                        </StickyTableRow>
                        <LastStickyTableRow>
                          <LastRowStickyTableCell>
                            <Typography variant="body2">
                              {t('janusConfig.dailyEntry.finalNumber')}
                            </Typography>
                          </LastRowStickyTableCell>
                          {entries?.finalValues.map((finalValue, index) => (
                            <LastRowStickyTableCell
                              key={`final-value-cell-${finalValue.specificKpiId}`}
                              sx={{alignItems: 'right'}}
                            >
                              <Box display="flex" justifyContent="flex-end">
                                {finalValue.unitTypeId !== UnitType.Numeric ? (
                                  <Typography>-</Typography>
                                ) : (
                                  <Controller
                                    control={control}
                                    name={`entries.${index}.value`}
                                    rules={{
                                      validate: (value) =>
                                        value === null ||
                                        parseFloat(value) >= 0 ||
                                        (finalValue.unitTypeId === UnitType.Time &&
                                          parseFloat(value) < 24) ||
                                        t('error.minValue', {value: 0})
                                    }}
                                    render={({field: {ref, value, onChange}}) => {
                                      const error = errors?.entries?.[index]?.value
                                      const helperText = (error && error.message) || ''
                                      return (
                                        <StyledTextField
                                          InputProps={{
                                            inputProps: {
                                              style: {
                                                textAlign: 'right',
                                                fontSize: '0.75rem',
                                                fontWeight: 400
                                              }
                                            }
                                          }}
                                          onChange={onChange}
                                          inputRef={ref}
                                          helperText={helperText}
                                          error={!!error}
                                          variant="outlined"
                                          value={value}
                                          {...dataTestId(
                                            `final-value-input-${finalValue.specificKpiId}`
                                          )}
                                        />
                                      )
                                    }}
                                  />
                                )}
                              </Box>
                            </LastRowStickyTableCell>
                          ))}
                        </LastStickyTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
              )}
            </Stack>
          </CardBox>
        </Grid>
      </Grid>
    </Stack>
  )
}
