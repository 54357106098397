import {MaterialType} from '@hconnect/common/types'

import {useSettingsQuery} from '../../../common/providers/ApiInterface'
import {GlobalMaterial, AutocompleteOption} from '../../types'

export const useGlobalMaterialsQuery = (materialType?: MaterialType) => {
  return useSettingsQuery('globalMaterials', [], {
    select: (data: GlobalMaterial[]): AutocompleteOption[] =>
      data
        .filter(
          (globalMaterial) => materialType === undefined || globalMaterial.type === materialType
        )
        .map((globalMaterial) => ({label: globalMaterial.name, id: globalMaterial.id.toString()}))
  })
}
